import React, { useEffect, useState } from 'react'
import { getQueueSize } from '../common/localCache';

const CacheSyncStatus = ({ onComplete = () => { } }) => {
    const [queueSize, setQueueSize] = useState(getQueueSize());

    useEffect(() => {
        const interval = setInterval(() => setQueueSize(getQueueSize() || onComplete() || 0), 2000);
        return () => clearInterval(interval);
    }, [onComplete]);

    return (
        <div className='mt-5 text-center'>
            <small className='text-warning mt-4'>Nu se poate lua în lucru un alt dosar sau palet❗</small>
            <p><b>🚫 --- » Sincronizare în derulare « --- 🚫</b></p>
            <p>Se transmit <b>( {queueSize} )</b> modificări din dosarul anterior.</p>
            <progress />
        </div>
    )
}

export default CacheSyncStatus