import { RefuzIcon } from "./StatusIcons";
import { Progress } from "./StatusLivrare";

export const LivrareFacturaStatus = ({
    title = "Colete încărcate " as "Colete încărcate " | "Facturi livrate ",
    doneCount = 0,
    totalCount = 0,
    refuzCount = 0,
  }) => {
    return (
      <h5 className="justify-content-between">
        {title === "Facturi livrate " && !!refuzCount && (
          <span>
            <RefuzIcon />
            <b className="text-larger text-danger"> {refuzCount}</b>
          </span>
        )}
        <span>
          {title}
          <b className="text-larger">
            <Progress total={totalCount} done={doneCount} />
          </b>
        </span>
      </h5>
    );
  };
  