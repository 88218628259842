import { useState } from "react";
import { Entity, EntityName, EntityType, FacturaEntity } from "../../../backend/src/shared/entity";
import env from "./env";
import { publish, subscribe } from "./events";
import { getCookie, loginUser } from "./user";

const localCache = new Map<string, any>();

const lipsaProcesVerbal = (a: FacturaEntity, b: FacturaEntity) => {
    if (
        a.ScanatLivrat &&
        a.ScanatLivrat + a.ScanatRefuz === a.NumarColete &&
        !a.ProcesVerbal
    ) {
        return -1;
    }
    if (
        b.ScanatLivrat &&
        b.ScanatLivrat + b.ScanatRefuz === b.NumarColete &&
        !b.ProcesVerbal
    ) {
        return 1;
    }
    if (
        a.ScanatLivrat + a.ScanatRefuz &&
        a.ScanatLivrat + a.ScanatRefuz < a.NumarColete
    ) {
        // a este factura in curs de livrare/scanare
        return -1;
    }
    if (
        b.ScanatLivrat + b.ScanatRefuz &&
        b.ScanatLivrat + b.ScanatRefuz < b.NumarColete
    ) {
        // b este factura in curs de livrare/scanare
        return 1;
    }
    if (a.ScanatLivrat + a.ScanatRefuz === a.NumarColete) {
        // a este deja livrata/finalizata
        return 1;
    }
    if (b.ScanatLivrat + b.ScanatRefuz === b.NumarColete) {
        // b este deja livrata/finalizata
        return -1;
    }
    return a.NumarOrdine < b.NumarOrdine ? -1 : 1; // după numarul de ordine din dosar
};

let processCollectionQueueIsActive = false;

const processCollectionQueue = () => { // sincronizare date locale cu sql server
    if (navigator.onLine && !processCollectionQueueIsActive) {
        const queue = getQueue();
        const collections = Object.keys(queue) as Array<keyof typeof queue>;
        collections
            .filter(c => queue[c].length)
            .forEach((collection) => {
                if (getCookie('token') === '') {
                    const sofer = localStorage.getItem('__sofer__');
                    if (!sofer) {
                        if (window.location.pathname !== '/login-sofer') window.location.pathname = '/login-sofer';
                        return console.warn('Aștept autentificare...');
                    }
                    loginUser(JSON.parse(sofer))
                        .then(processCollectionQueue)
                        .catch(console.error); // autentificare automată cu datele din localStorage
                    return; // nu trimit date dacă nu sunt autentificat
                }

                if (processCollectionQueueIsActive) return; // nu trimit mai mult de 1 colecție odată (dbo.Factura & dbo.Colet se pot bloca unul pe altul)
                processCollectionQueueIsActive = true;
                const isCollectionFactura = collection === 'dbo.Factura'; // la factura am si poza care e mai mare, trimit mai putine
                postCollection(collection, queue[collection].slice(0, isCollectionFactura ? 5 : 50)) // trimit maxim 5/50 de entități odată
                    .finally(() => processCollectionQueueIsActive = false);
            });
    }
}

export function getQueueSize() {
    const queue = getQueue();
    const collections = Object.keys(queue) as Array<keyof typeof queue>;
    return collections
        .map(c => queue[c].length)
        .reduce((a, b) => a + b, 0);
}

setInterval(processCollectionQueue, 3000);

export function postCollection<T extends EntityName>(collection: T, entities: EntityType<T>[]) {
    return new Promise<EntityType<T>[]>((resolve, reject) => {
        fetch(`${env.API_URL}/collection/${collection}`, {
            method: "POST",
            credentials: 'include',
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(entities),
        })
            .then((response) => {
                if (!response.ok) {
                    throw new Error(`${response.status} ${response.statusText}`);
                }
                entities.forEach((entity) => removeQueue(collection, entity));
                return response.json();
            })
            .then(resolve)
            .catch(reject);
    });
}

export function getCollection<T extends EntityName>(collection: T): EntityType<T>[] {
    if (!localCache.has(collection)) {
        const orderBy = collection === 'dbo.Factura' ? lipsaProcesVerbal : undefined;
        localCache.set(collection, JSON.parse(localStorage.getItem(collection) || '[]').sort(orderBy));
    }
    return localCache.get(collection);
    // return JSON.parse(localStorage.getItem(collection) || '[]');
}

export function setCollection<T extends EntityName>(collection: T, entities: EntityType<T>[]) {
    localCache.set(collection, entities);
    localStorage.setItem(collection, JSON.stringify(entities));
    if ('dbo.Colet' === collection && getCollection('dbo.Dosar')[0]) {
        // calculez totaluri în dosar și factură la fiecare modificare a coletelor
        setCollection(
            "dbo.Dosar",
            entityReducer(
                getCollection("dbo.Dosar"),
                getCollection("dbo.Colet"),
                ["Id", "IdDosar"],
                ["ScanatCamion", "ScanatLiber", "ScanatLivrat", "ScanatPalet", "ScanatRefuz", "ScanatRetur"]
            )
        );
        setCollection(
            "dbo.Factura",
            entityReducer(
                getCollection("dbo.Factura"),
                getCollection("dbo.Colet"),
                ["Id", "IdFactura"],
                ["ScanatCamion", "ScanatLiber", "ScanatLivrat", "ScanatPalet", "ScanatRefuz", "ScanatRetur"]
            ).sort(lipsaProcesVerbal)
        );
    }
    publish('localCacheChanged');
}

export function getEntity<T extends EntityName>(collection: T, id: number) {
    return getCollection(collection).find((item) => item.Id === id);
}

export function setEntity<T extends EntityName>(collection: T, entity: EntityType<T>) {
    if (entity?.Id) {
        const entities = getCollection(collection);
        const savedEntity = entities.find((item) => item.Id === entity.Id);
        savedEntity ?
            Object.assign(savedEntity, entity) :
            entities.push(entity);
        if (collection === 'dbo.Factura') (entities as FacturaEntity[]).sort(lipsaProcesVerbal);
        setCollection(collection, entities);
    }
}

export function getQueue<T extends EntityName>(): { [key in T]: EntityType<T>[] } {
    return JSON.parse(localStorage.getItem('__queue__') || '{}');
}

export function setQueue<T extends EntityName>(collection: T, entity: EntityType<T>) {
    if (entity?.Id) {
        setEntity(collection, entity);
        const queue = getQueue();
        queue[collection] = queue[collection] || [];
        // update the first element from the queue that has the same Id as the entity if exists
        const index = queue[collection].findIndex((item) => item.Id === entity.Id);
        if (index >= 0) {
            queue[collection][index] = entity;
        } else {
            queue[collection].push(entity);
        }
        localStorage.setItem('__queue__', JSON.stringify(queue));
        processCollectionQueue();
    }
    return entity;
}

export function removeQueue<T extends EntityName>(collection: T, entity: EntityType<T>) {
    if (entity?.Id) {
        // setEntity(collection, entity);
        const queue = getQueue();
        queue[collection] = queue[collection] || [];
        // remove the first element from the queue that has the same Id as the entity if exists
        const index = queue[collection].findIndex((item) => item.Id === entity.Id);
        if (index >= 0) {
            queue[collection].splice(index, 1);
        }
        queue[collection].length || delete queue[collection]; // delete the collection from the queue if empty
        return localStorage.setItem('__queue__', JSON.stringify(queue));
    }
}

export function clearQueue() {
    return localStorage.setItem('__queue__', '{}');
}

export function entityReducer<T extends Entity, S extends Entity>(
    target: T[], // target collection
    source: S[], // source collection
    relation: [keyof T, keyof S], // relation field
    fields: Array<keyof T & keyof S>, // fields to reduce
) {
    const [pk, fk] = relation;//Array.from(relation.entries())[0];
    const mapped = new Map(
        target.map((t) => [t, source.filter((s) => +s[fk] === +t[pk])])
    );
    mapped.forEach((s, t) => {
        fields.forEach((p) => {
            t[p] = s.filter((s) => !!s[p]).length as any;
        });
    });
    return target;
};

const localCacheApi = () => ({
    getQueueSize,
    getCollection,
    setCollection,
    getEntity,
    setEntity,
    getQueue,
    setQueue,
    removeQueue,
    clearQueue,
    dosar: getCollection('dbo.Dosar')[0] || {},
    facturi: getCollection('dbo.Factura'),
    colete: getCollection('dbo.Colet'),
});

const useLocalCache = () => {
    const [local, setLocal] = useState(localCacheApi());
    subscribe('localCacheChanged', () => setLocal(localCacheApi()));
    return local;
}

export default useLocalCache;
