import { SyntheticEvent, useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { publish } from "../common/events";

const CameraSnapshot = ({
  active = true,
  handleResult = (base64image: string) => {},
}) => {
  const video = useRef<HTMLVideoElement>(null);
  const canvas = useRef<HTMLCanvasElement>(null);
  const [stream, setStream] = useState<MediaStream>();
  const [isOpen, setIsOpen] = useState(active);

  useEffect(() => publish("CameraSnapshotChanged", isOpen));

  useEffect(() => {
    const startCamera = async () => {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({
          audio: false,
          video: {
            facingMode: "environment",
          },
        });
        setStream(stream);
        if (video.current) {
          video.current.srcObject = stream;
        }
      } catch (err) {
        console.log(err);
        alert("Camera nu poate fi pornită!\n" + JSON.stringify(err));
      }
    };
    console.log("CameraSnapshot:", { isOpen });
    if (isOpen) {
      console.log("CameraSnapshot: startCamera");
      startCamera();
    }
  }, [isOpen, video, setStream]);

  const handleCapture = (e: SyntheticEvent) => {
    e.preventDefault();
    if (canvas?.current && video?.current) {
      canvas.current.width = video.current.videoWidth;
      canvas.current.height = video.current.videoHeight;
      canvas.current
        ?.getContext("2d")
        ?.drawImage(
          video.current,
          0,
          0,
          canvas.current.width,
          canvas.current.height
        );

      let base64image = canvas.current.toDataURL("image/jpeg");
      stream?.getTracks().forEach((track) => track.stop());
      setIsOpen(false);
      publish("CameraSnapshotChanged", false);
      handleResult(base64image);
    }
  };

  return active ? (
    <div style={{ position: "relative" }}>
      <video
        ref={video}
        id="video"
        muted
        autoPlay
        playsInline
        hidden={!isOpen}
        className="w-100"
      ></video>
      <canvas
        ref={canvas}
        id="canvas"
        hidden={isOpen}
        className="w-100"
      ></canvas>
      <h3
        className="w-100 text-center"
        style={{ position: "absolute", bottom: "0", zIndex: "1" }}
      >
        <Link to="/" className="text-larger" onClick={handleCapture}>
          <i className="fa-2xl fa-solid fa-circle-camera text-muted"></i>
        </Link>
      </h3>
    </div>
  ) : (
    <></>
  );
};

export default CameraSnapshot;
