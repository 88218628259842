import { useState } from "react";
import ScannerWebcam from "../../components/scanner/ScannerWebcam";
import { subscribe } from "../../common/events";
import { ReturIcon } from "../../components/livrare/StatusIcons";
import useModal from "../../hooks/useModal";
import ScannerInput from "../../components/scanner/ScannerInput";
import { Progress } from "../../components/livrare/StatusLivrare";
import { Link } from "react-router-dom";
import { ColetEntity, DosarEntity } from "../../../../backend/src/shared/entity";
import sql from "../../common/sql";
import { toast } from "sonner";
import ErrorBox from "../../components/ErrorBox";

const DepozitReturPage = () => {
  const [isScanning, setIsScanning] = useState(false);
  const [error, setError] = useState("");
  const [dosar, setDosar] = useState<DosarEntity>();
  const [colet, setColet] = useState<ColetEntity>();
  const modal = useModal();

  const handleSearch = (barcode: string) => {
    setError("");
    let message = "";
    barcode = barcode.replace('B2B-', '')
    if (barcode) {
      sql // caut dosarul aferent coletului scanat, el trebuie sa fie marcat ca paletat
        .coletReturByBarcodeQuery({ barcode })
        .then(([[dosar], [colet]]: [DosarEntity[], ColetEntity[]]) => {
          if (!dosar?.Id) {
            message = "Nu am găsit dosar pentru codul de bare scanat!";
          } else if (!dosar.ScanatRefuz) {
            message = `Dosarul "${dosar.Nume}" nu are colete marcate "Refuzat"!`;
          } else if (!colet.ScanatRefuz) {
            message = `Coletul ${colet?.Descriere} nu este marcat "Refuzat"!`
          } else if (colet.ScanatRetur) {
            toast.success(`Coletul "${colet.Descriere}" a fost marcat "Retur"!`);
          } else {
            message = "Ceva nu a mers bine!";
          }
          if (message) { setError(message); toast.error(message); }
          if (dosar) {
            setDosar(dosar); setColet(colet);
            if (dosar.ScanatRetur === dosar.ScanatRefuz) {
              isScanning && setIsScanning(false);
              modal(
                `Toate coletele din dosar au fost marcate "retur".`,
                "Scanare retur completă"
              );
            }
          }
        })
        .catch((err) => setError(err.message || err));
    }
  }

  subscribe("CameraScanningChanged", ({ detail }) => setIsScanning(detail));

  return (
    <>
      {isScanning ? (
        <ScannerWebcam
          {...{ handleSearch, isScanning, setIsScanning }}
        >
          <DepozitReturProgress {...{ colet, dosar }} />
        </ScannerWebcam>
      ) : (
        <div>
          <h5>
            <ReturIcon /> &nbsp; Retur colete
          </h5>
          <ScannerInput {...{ handleSearch }} />
          <ErrorBox children={error} />
          <DepozitReturProgress {...{ colet, dosar }} />
        </div>
      )}
    </>
  );
};

export default DepozitReturPage;

const DepozitReturProgress = ({ colet = {} as ColetEntity, dosar = {} as DosarEntity }) => {
  if (!dosar?.Id) return <h5>Scanează un colet</h5>;
  return (
    <>
      <h4 className="mb-1">
        <ReturIcon />
        &nbsp;
        <Progress total={dosar.ScanatRefuz} done={dosar.ScanatRetur} />
        <Link className="ms-2" to={`/dosar-details/${dosar?.Id}`}>
          {dosar?.Nume}
        </Link>
      </h4>
      <small className="text-muted">{colet?.Descriere}</small>
      {dosar.ScanatRetur === dosar.ScanatRefuz && (
        <p className="text-success">
          Toate colete ( {dosar.ScanatRetur} / {dosar.ScanatRefuz} ) au fost marcate "retur"!
        </p>
      )}
    </>
  );
}