import { SyntheticEvent, useState } from "react";
import { Link, useParams } from "react-router-dom";
import ScannerWebcam from "../../components/scanner/ScannerWebcam";
import {
  ColetEntity,
  FacturaEntity,
  DosarEntity,
} from "../../../../backend/src/shared/entity";
import NavbarTitle from "../../components/navbar/NavbarTitle";
import ErrorBox from "../../components/ErrorBox";
import sql, { SqlError } from "../../common/sql";
import { StatusLivrareIcon } from "../../components/livrare/StatusLivrareIcon";
import Phone from "../../components/Phone";
import { useQuery } from "@tanstack/react-query";
import { toast } from "sonner";
import {
  CamionIcon,
  LivratIcon,
  PaletIcon,
  RefuzIcon,
  ReturIcon,
} from "../../components/livrare/StatusIcons";
import useModal from "../../hooks/useModal";
import ScannerInput from "../../components/scanner/ScannerInput";
import { subscribe } from "../../common/events";
import { formatDate } from "../../common/utils";
import { useUser } from "../../hooks/useUser";
import ValoareDecontFactura from "../../components/livrare/ValoareDecontFactura";
import ProcesVerbalViewer from "../../components/livrare/ProcesVerbalViewer";

const DosarDetailsPage = () => {
  const { idDosar = "" } = useParams();
  const [isScanning, setIsScanning] = useState(false);
  const [detailsId, setDetailsId] = useState(0);
  const [info, setInfo] = useState("");
  const [error, setError] = useState<SqlError | null>();
  const [dosar, setDosar] = useState<DosarEntity>({} as DosarEntity);
  const [facturi, setFacturi] = useState<Array<FacturaEntity>>([]);
  const [colete, setColete] = useState<Array<ColetEntity>>([]);
  const [loading, setLoading] = useState(true);
  const modal = useModal();
  const { user } = useUser();
  const paleti = Array.from(
    new Set(colete.filter((c) => c.IndexPalet).map((c) => c.IndexPalet))
  ).sort();
  const setEntities = (
    entities: [Array<DosarEntity>, Array<FacturaEntity>, Array<ColetEntity>]
  ) => {
    setDosar(entities[0][0]);
    setFacturi(entities[1]);
    setColete(entities[2]);
    setLoading(false);
  };

  useQuery({
    queryKey: ["DosarDetails", idDosar],
    queryFn: () => sql.dosarDetailsQuery({ idDosar }),
    onSuccess: setEntities,
    onError: (e: SqlError) => [setError(e), setLoading(false)],
  });

  subscribe("CameraScanningChanged", () => setError(undefined));

  const reportUrl = (
    template: string,
    params: { [key: string]: number | string | Date }
  ) =>
    "/api/report/" +
    btoa(
      JSON.stringify({
        template,
        params,
      })
    );

  const reorderModal = ({ Id, NumarOrdine, Document }: FacturaEntity) => {
    modal(
      <label>
        Număr ordine:
        <input
          type="number"
          name="numarOrdine"
          defaultValue={NumarOrdine}
          onChange={(e) => [
            e.preventDefault(),
            (NumarOrdine = parseInt(e.target.value)),
          ]}
        />
      </label>,
      `Modifică ordine factură ${Document}`,
      <a
        href="/"
        role="button"
        onClick={(e) => [
          e.preventDefault(),
          handleNumarOrdine(Id, NumarOrdine),
          modal(),
        ]}
      >
        <i className="fa-solid fa-arrow-up-short-wide"></i>
        Reordonează
      </a>
    );
  };

  const handleSearch = (barcode: string) => {
    setError(undefined);
    setInfo("");
    setLoading(true);
    sql
      .dosarAdaugaFacturaQuery({
        idDosar,
        idFactura: barcode,
      })
      .then(setEntities)
      .then(() => {
        setInfo(`Factura ${barcode} a fost adăugată la dosar.`);
        toast.success(`Factura ${barcode} a fost adăugată la dosar.`);
      })
      .catch((e) => [setError(e), toast.error(JSON.parse(e).message)])
      .finally(() => setLoading(false));
  };

  const handleNumarOrdine = (idFactura: number, numarOrdine: number) => {
    let factura = facturi.find((f) => f.Id === idFactura);
    if (numarOrdine && numarOrdine !== factura?.NumarOrdine) {
      sql
        .saveEntity("dbo.Factura", {
          ...factura,
          NumarOrdine: numarOrdine,
        })
        .then(() => sql.dosarDetailsQuery({ idDosar }))
        .then(setEntities)
        .then(() => toast.success(`Facturile au fost reordonate.`))
        .catch(setError)
        .finally(() => setLoading(false));
    }
  };

  const handleDeleteFactura =
    (factura: FacturaEntity) => (e: SyntheticEvent) => {
      e.preventDefault();
      if (
        window.confirm(
          `Ștergi factura ${factura.Document} - ${factura.Cumparator} din dosar?`
        )
      ) {
        setLoading(true);
        sql
          .dosarStergeFacturaQuery({ idDosar, idFactura: factura.Id })
          .then(setEntities)
          .then(() =>
            toast.success(
              `Factura ${factura.Document} a fost ștearsă din dosar.`
            )
          )
          .catch(setError)
          .finally(() => setLoading(false));
      }
    };

  const handleBarcodeIconClick = async (coletId: number) => {
    const response = await fetch(`/api/coletBarcode/${coletId}`);
    const barcodeImage = await response.text();

    modal(
      <div style={{ backgroundColor: "white", padding: "10px" }}>
        <img src={barcodeImage} alt="Barcode" />
      </div>,
      `Colet ID: ${coletId}`
    );
  };

  return (
    <>
      <NavbarTitle icon="fa-folder-open">Dosar transport</NavbarTitle>
      {isScanning ? (
        <ScannerWebcam {...{ handleSearch, isScanning, setIsScanning }}></ScannerWebcam>
      ) : (
        <div>
          <p className="mb-1">
            <b>
              <StatusLivrareIcon {...dosar} /> {dosar.Nume}
            </b>
            <Link to={`/dosar-edit/${idDosar}`}>
              <i className="ms-3 fa-fw fa-light fa-pen"></i>
              <small> Modifică</small>
            </Link>
            <Link
              to={reportUrl("qr-dosar", {
                idDosar,
                stamp: new Date(),
              })}
              target="_blang"
            >
              <i className="ms-3 py-3 fa-fw fa-solid fa-qrcode"></i>{" "}
              <small>QR Dosar</small>
            </Link>
            <Link
              to={reportUrl("comandaTransport", {
                idDosar,
                stamp: new Date(),
              })}
              target="_blang"
            >
              <i className="ms-3 py-3 fa-fw fa-solid fa-print"></i>{" "}
              <small>Comanda transport</small>
            </Link>

            {paleti.map((palet) => (
              <Link
                key={palet}
                to={reportUrl("qr-palet", { palet, idDosar })}
                target="_blang"
              >
                <i className="ms-3 py-3 fa-fw fa-solid fa-print"></i>{" "}
                <small>Palet {palet}</small>
              </Link>
            ))}
          </p>
          <label>
            {dosar.Descriere}{" "}
            {facturi.length ? (
              <small>
                ({" "}
                <ins>
                  <b>{facturi.length} facturi </b>
                </ins>{" "}
                cu un total de{" "}
                <ins>
                  <b>
                    {facturi.reduce((prev, next) => prev + next.NumarColete, 0)}{" "}
                    colete
                  </b>
                </ins>
                {dosar.Paletare && (
                  <>
                    , ambalare pe
                    <b>
                      <ins> {dosar.NumarPaleti || "?"} paleți</ins>
                    </b>
                  </>
                )}
                )
              </small>
            ) : (
              <small>
                <ins>
                  ( Adaugă facturi la dosar prin scanare sau tastare cod de bare
                  )
                </ins>{" "}
              </small>
            )}
          </label>
          <p>
            <i className="fa-light fa-calendar-days me-2"></i>
            Livrare {formatDate(dosar.DataLivrare, "text")}
          </p>
          <ScannerInput
            {...{
              handleSearch,
              placeholder: "Scanează sau scrie cod factură ...",
            }}
          />
        </div>
      )}

      <div>
        {loading && <progress />}
        <ErrorBox>{error}</ErrorBox>
        {info && <p className="text-success">{info}</p>}
        <figure>
          <table>
            <thead>
              <tr>
                <th>#</th>
                <th className="m-0 p-0">Număr Colete — Factură</th>
                <th className="text-end">Decont</th>
                <th className="m-0 p-0">PV</th>
                <th>Cumpărător</th>
                <th>Telefon</th>
                <th>Localitate</th>
                <th></th>
              </tr>
            </thead>
            {facturi.map((factura) => (
              <tbody key={factura.Id}>
                <tr>
                  <td>
                    <Link
                      to="/"
                      onClick={(e) => [
                        e.preventDefault(),
                        reorderModal(factura),
                      ]}
                    >
                      {factura.NumarOrdine}.
                    </Link>
                  </td>
                  <td className="m-0 p-0">
                    <Link
                      to="/"
                      onClick={(e) => [
                        e.preventDefault(),
                        setDetailsId(factura.Id === detailsId ? 0 : factura.Id),
                      ]}
                    >
                      <StatusLivrareIcon {...factura} /> &nbsp; (
                      <b> {factura.NumarColete} </b>) {" — "}
                      {factura.Document}
                      {/* <i className="fa-fw fa-solid fa-chevrons-down ms-2"></i> */}
                    </Link>
                  </td>
                  <td className="text-end">
                    <ValoareDecontFactura {...factura} />
                  </td>
                  <td className="m-0 p-0">
                    <ProcesVerbalViewer procesVerbal={factura.ProcesVerbal} document={factura.Document} />
                  </td>
                  <td>{factura.Cumparator}</td>
                  <td>
                    <Phone>{factura.Telefon}</Phone>
                  </td>
                  <td>{factura.Oras}</td>
                  <td className="text-end p-0">
                    <Link
                      className="text-danger"
                      to="/"
                      onClick={handleDeleteFactura(factura)}
                    >
                      <i className="fa-lg fa-duotone fa-trash-xmark"></i>
                    </Link>
                  </td>
                </tr>
                {detailsId === factura.Id && (
                  <tr>
                    <td></td>
                    <td colSpan={7} className="m-0 p-0">
                      {colete
                        .filter((colet) => colet.IdFactura === factura.Id)
                        .map((colet) => (
                          <p key={colet.Id}>
                            <StatusLivrareIcon {...colet} /> &nbsp;
                            {colet.Descriere}
                            &nbsp;{" "}
                            <a
                              href="/"
                              onClick={(e) => {
                                e.preventDefault();
                                handleBarcodeIconClick(colet.IdSursa);
                              }}
                            >
                              <i className="fa fa-barcode" />
                            </a>
                            <ColetTrackingHistory {...colet} />
                          </p>
                        ))}
                    </td>
                  </tr>
                )}
              </tbody>
            ))}
            <tbody>
              <tr>
                <td colSpan={2} className="text-end">
                  <b>Total decont</b>
                </td>
                <td className="text-end">
                  <b>{(dosar.Valoare - dosar.ValoareRefuz).toFixed(2)} lei</b>
                </td>
                <td colSpan={5} className="text-center">
                  <label
                    htmlFor="switch"
                    className={dosar.FinalizatDe ? "text-smaller" : ""}
                  >
                    <input
                      type="checkbox"
                      id="switch"
                      name="switch"
                      role="switch"
                      checked={!!dosar.FinalizatDe}
                      onChange={(e) => [
                        e.preventDefault(),
                        setLoading(true),
                        sql
                          .saveEntity("dbo.Dosar", {
                            ...dosar,
                            FinalizatDe: e.target.checked ? user.email : null,
                            FinalizatLa: e.target.checked ? new Date() : null,
                          })
                          .then(setDosar)
                          .finally(() => setLoading(false)),
                      ]}
                    />
                    {dosar.FinalizatDe ? (
                      <>
                        Finalizat la{" "}
                        <b>{formatDate(dosar.FinalizatLa, "long")}</b> de{" "}
                        <b>{dosar.FinalizatDe}</b>
                      </>
                    ) : (
                      `Marchează ca finalizat`
                    )}
                  </label>
                </td>
              </tr>
            </tbody>
          </table>
        </figure>
      </div>
    </>
  );
};

export default DosarDetailsPage;

const ColetTrackingHistory = ({
  ScanatPalet,
  ScanatCamion,
  ManualCamion,
  ScanatLiber,
  ScanatLivrat,
  ManualLivrat,
  ScanatRefuz,
  ManualRefuz,
  ScanatRetur,
  MotivRetur,
}: ColetEntity) => {
  return (
    <>
      {(ScanatPalet || ScanatLiber) && (
        <span className="ms-2">
          <PaletIcon /> {formatDate(ScanatPalet || ScanatLiber || "", "long")}
        </span>
      )}
      {ScanatCamion && (
        <span className="ms-2">
          <CamionIcon />{ManualCamion && '👇'} {formatDate(ScanatCamion || "", "long")}
        </span>
      )}
      {ScanatLivrat && (
        <span className="ms-2">
          <LivratIcon />{ManualLivrat && '👇'} {formatDate(ScanatLivrat || "", "long")}
        </span>
      )}
      {ScanatRefuz && (
        <span className="ms-2">
          <RefuzIcon />{ManualRefuz && '👇'} {formatDate(ScanatRefuz || "", "long")}{" "}
          <small className="text-warning"> ( {MotivRetur} ) </small>
        </span>
      )}
      {ScanatRetur && (
        <span className="ms-2">
          <ReturIcon /> {formatDate(ScanatRetur || "", "long")}
        </span>
      )}
    </>
  );
};
