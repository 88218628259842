import { useEffect, useState } from "react";

const BarcodeFacturiPage = () => {
  const [codes, setCodes] = useState<
    Array<{ IdFactura: string; QrCode: string; BarCode: string }>
  >([]);

  useEffect(() => {
    const loadQrCodes = async () => {
      const result = await fetch("/api/facturiQrCode");
      const json = await result.json();
      setCodes(json);
    };
    loadQrCodes();
  }, [setCodes]);

  return (
    <div className="row">
      {codes.map((code) => (
        <div key={code.IdFactura} className="col-md-6 col-xl-4 my-3 p-2">
          <img
            src={code.BarCode}
            alt="QrCode"
            style={{ background: "white", padding: "1rem" }}
          />
        </div>
      ))}
    </div>
  );
};

export default BarcodeFacturiPage;
