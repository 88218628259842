import { ColetEntity } from "../../../../backend/src/shared/entity";
import useLocalCache from "../../common/localCache";
import { CamionIcon, LivratIcon, RefuzIcon } from "./StatusIcons";

const OperareManualaStatusColet = ({ colet = null as ColetEntity | null, setColet = (colet: ColetEntity | null) => { }, etapa = "livrare" as "livrare" | "încărcare" }) => {
  const { setQueue } = useLocalCache();
  const isEnabled = () => {
    if (etapa === "încărcare" && colet?.ScanatCamion) return "enabled";
    if (etapa === "livrare" && (colet?.ScanatLivrat || colet?.ScanatRefuz)) return "enabled";
    return "disabled";
  }
  if (!colet) return null;
  return (
    <dialog open>
      <article>
        <i className="text-muted text-larger">{colet?.Descriere}</i>
        <hr />
        {etapa === "livrare" && <p>
          <label>
            <input type="radio" name="status" value="livrat" checked={!!colet?.ScanatLivrat}
              onChange={e => setColet({ ...colet!, ScanatRefuz: null, MotivRetur: "", ScanatLivrat: new Date(), ManualLivrat: true, ScanatCamion: colet.ScanatCamion || new Date() })} />
            Marcare status colet - <LivratIcon /> Livrat
          </label>
        </p>}
        {etapa === "livrare" && <p>
          <label>
            <input type="radio" name="status" value="refuzat" checked={!!colet?.ScanatRefuz}
              onChange={e => setColet({ ...colet!, ScanatRefuz: new Date(), MotivRetur: "pierdut", ScanatLivrat: null, ManualRefuz: true })} />
            Marcare status colet - <RefuzIcon /> Pierdut
          </label>
        </p>}
        {etapa === "încărcare" && <p>
          <label>
            <input type="radio" name="status" value="incarcat" checked={!!colet?.ScanatCamion}
              onChange={e => setColet({ ...colet!, ScanatCamion: new Date(), ManualCamion: true })} />
            Marcare status colet - <CamionIcon /> încărcat
          </label>
        </p>}
        <footer>
          <a href="/" role="button" className="secondary" onClick={e => [e.preventDefault(), setColet(null)]}>Renunț</a>
          <a href="/" role="button" className={isEnabled()} onClick={e => [e.preventDefault(), colet && setQueue("dbo.Colet", colet), setColet(null)]}>Confirm</a>
        </footer>
      </article>
    </dialog>
  )
}

export default OperareManualaStatusColet;
