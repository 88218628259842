import LoginPage from "./pages/user/LoginPage";
import { createContext, useState } from "react";
import ModalSubscriber from "./components/ModalSubscriber";
import { Toaster } from "sonner";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { UserRole } from "./common/user";
import { useUser } from "./hooks/useUser";
import Forbidden from "./components/Forbidden";
import Router from "./components/Router";
const queryClient = new QueryClient();

export const TitleContext = createContext<{
  title: JSX.Element;
  setTitle: (title: JSX.Element) => void;
}>(null as any);

export function Auth(component: JSX.Element, ...roles: UserRole[]) {
  roles = roles.length ? roles : ["admin", "operator"];
  const { user } = useUser();
  if (!user?.email) return <LoginPage />;
  if (!roles.includes(user.role)) return <Forbidden />;
  return component;
}

const App = () => {
  const [title, setTitle] = useState(<span>Laguna delivery</span>);
  return (
    <div className="App">
      <QueryClientProvider client={queryClient}>
        <TitleContext.Provider value={{ title, setTitle }}>
          <Router />
          <Toaster
            closeButton
            richColors
            position="bottom-right"
            theme="system"
            expand
          />
          <ModalSubscriber />
        </TitleContext.Provider>
      </QueryClientProvider>
    </div>
  );
};

export default App;
