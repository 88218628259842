import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
type Model = {
  IdFactura: string;
  Document: string;
  Descriere: string;
  IdColet: string;
  QrCode: string;
  BarCode: string;
};
const BarcodeColetePage = () => {
  const { idDosar } = useParams();
  const [codes, setCodes] = useState<Array<Model>>([]);
  const [facturi, setFacturi] = useState<
    Array<{ id: string; document: string; count: number }>
  >([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const facturi: Array<{ id: string; document: string; count: number }> = [];
    codes.forEach((c) => {
      const id = c.IdFactura;
      const document = c.Document;
      const index = facturi.findIndex((f) => f.id === id);
      if (index === -1) {
        facturi.push({ id, document, count: 1 });
      } else {
        facturi[index].count++;
      }
    });
    setFacturi(facturi);
  }, [codes, setFacturi]);

  useEffect(() => {
    const loadQrCodes = async () => {
      const result = await fetch(`/api/coleteQrCode/${idDosar}`);
      const json = await result.json();
      setCodes(json);
      setLoading(false);
    };
    loadQrCodes();
  }, [idDosar, setCodes]);

  if (loading) return <progress></progress>;

  return (
    <>
      {facturi.map((factura) => (
        <div className="row" key={factura.id}>
          <p>
            Factura &nbsp;
            <b className="text-warning text-large">{factura.document}</b>
            {` ( ${factura.id} ) conține `}
            <b className="text-warning text-large"> {factura.count} colete </b>
          </p>
          {codes
            .filter((code) => code.IdFactura === factura.id)
            .map((code) => (
              <div key={code.IdColet} className="col-6 col-lg-4 col-xl-3 mb-4">
                <img
                  src={code.BarCode}
                  alt="QrCode"
                  style={{ background: "white", padding: "1rem" }}
                />
                <small>{code.Descriere}</small>
              </div>
            ))}
          <hr />
        </div>
      ))}
    </>
  );
};

export default BarcodeColetePage;
