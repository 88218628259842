import { useScanner } from "../hooks/useScanner";

const EmbeddedScannerSwitch = () => {
  const { embeddedScanner, setEmbeddedScanner } = useScanner();
  return (
    <label htmlFor="embeddedScanner" className="mb-4">
      <input
        type="checkbox"
        id="embeddedScanner"
        name="embeddedScanner"
        role="switch"
        checked={embeddedScanner}
        onChange={(e) => [setEmbeddedScanner(e.target.checked)]}
      />
      Echipament cu scanner încorporat
    </label>
  );
};

export default EmbeddedScannerSwitch;
