import {
  ColetEntity,
  DosarEntity,
  FacturaEntity,
} from "../../../../backend/src/shared/entity";

import {
  CamionIcon,
  DepozitIcon,
  LiberIcon,
  LivratIcon,
  PaletIcon,
  RefuzIcon,
  ReturIcon,
} from "./StatusIcons";

export const StatusLivrareIcon = (
  props: ColetEntity | FacturaEntity | DosarEntity
) => {
  if (isColetEntity(props as ColetEntity)) {
    const {
      ScanatRetur,
      ScanatRefuz,
      ScanatLivrat,
      ScanatCamion,
      ScanatPalet,
      ScanatLiber,
    } = props as ColetEntity;
    // ColetEntity
    if (ScanatRetur) return <ReturIcon />;
    if (ScanatRefuz) return <RefuzIcon />;
    if (ScanatLivrat) return <LivratIcon />;
    if (ScanatCamion) return <CamionIcon />;
    if (ScanatLiber) return <LiberIcon />;
    if (ScanatPalet) return <PaletIcon />;
    return <DepozitIcon />;
  } else {
    const {
      ScanatRetur,
      ScanatRefuz,
      ScanatLivrat,
      ScanatCamion,
      ScanatPalet,
      ScanatLiber,
      NumarColete,
    } = props as FacturaEntity | DosarEntity;
    // FacturaEntity | DosarEntity
    if (ScanatRetur && ScanatRetur === ScanatRefuz) return <ReturIcon />;
    if (ScanatRefuz) return <RefuzIcon />;
    if (ScanatLivrat === NumarColete) return <LivratIcon />;
    if (ScanatCamion === NumarColete) return <CamionIcon />;
    if (ScanatPalet + ScanatLiber === NumarColete) return <PaletIcon />;
    return <DepozitIcon />;
  }
};

function isColetEntity(
  entity: ColetEntity | FacturaEntity | DosarEntity
): entity is ColetEntity {
  return (entity as ColetEntity).IndexPalet !== undefined;
}
