import { useNavigate, useParams } from "react-router-dom";
import sql from "../../common/sql";
import LoginSofer from "../../components/LoginSofer";
import ErrorBox from "../../components/ErrorBox";
import LivrareFacturaList from "../../components/livrare/LivrareFacturaList";
import { useUser } from "../../hooks/useUser";
import { publish } from "../../common/events";
import { toast } from "sonner";
import { useQuery } from "@tanstack/react-query";
import { useActiveRoute } from "../../hooks/useActiveRoute";
import CacheSyncStatus from "../../components/CacheSyncStatus";
import { ColetEntity, DosarEntity, FacturaEntity, MotivReturEntity } from "../../../../backend/src/shared/entity";
import useLocalCache from "../../common/localCache";

const DosarScanPage = () => {
  const { idDosar = "", paletQR = "" } = useParams();
  const { dosar, setCollection, setQueue, clearQueue, getQueueSize } = useLocalCache();
  const { Id: IdDosar = 0, Nume: NumeDosar = "" } = dosar;
  const { user, setUser } = useUser();
  const { setActiveRoute } = useActiveRoute();
  const navigate = useNavigate();

  const handleQueryCompleted = (data: [DosarEntity[], FacturaEntity[], ColetEntity[], MotivReturEntity[]]) => {
    const [[dosar], facturi, colete, motiveRetur] = data;
    if (!dosar?.Id) return window.alert(`Dosarul ( ${idDosar} ) nu există!`);
    if (IdDosar && IdDosar !== dosar.Id) {
      const message = paletQR
        ? `Paletul scanat nu aparține de "${NumeDosar}" !\nDoriți trecerea la dosarul "${dosar.Nume}" ?`
        : `Dosarul în lucru este "${NumeDosar}" !\nDoriți trecerea la dosarul "${dosar.Nume}" ?`;
      if (!window.confirm(message)) return dosar.Id = IdDosar;
    }
    clearQueue();
    dosar.Sofer = user.email.split("@")[0];
    dosar.Auto = user.email.split("@")[1];
    setCollection("dbo.Dosar", [dosar]);
    setCollection("dbo.Colet", colete);
    setCollection("dbo.Factura", facturi);
    setCollection("dbo.MotivRetur", motiveRetur);
    publish("DosarChanged", dosar);
    publish("ColetChanged", colete);
    publish("FacturaChanged", facturi);
    toast.success("Dosarul a fost actualizat!");
    setQueue("dbo.Dosar", dosar);
    if (
      dosar.Paletare &&
      dosar.ScanatCamion < dosar.NumarColete
    ) {
      // transferul pe camion nu este complet,
      // redirectez la scanare camion a paleților/coletelor libere
      setTimeout(() => navigate(`/camion-palet/${paletQR}`));
    } else {
      setActiveRoute();
    }
  }

  const { isLoading, isRefetching, error, data, refetch } = useQuery({
    queryKey: ["DosarDetailsQuery", idDosar, paletQR, user.email, window.navigator.onLine],
    queryFn: ({ queryKey: [, idDosar] }) =>
      user.email && window.navigator.onLine
        ? sql.dosarDetailsQuery({ idDosar })
        : Promise.resolve(null),
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchInterval: false,
  });

  if (getQueueSize()) { // sincronizare date locale cu sql server este în curs
    return <CacheSyncStatus onComplete={() => refetch()} />; // la finalul sincronizării se reîncarcă datele de pe server
  }

  if (!isLoading && !isRefetching && !error && data && user.email) {
    handleQueryCompleted(data);
  }

  if (!user.email) return <LoginSofer handleLogin={setUser} />;
  if (error) return <ErrorBox>{error}</ErrorBox>;
  if (isLoading) return <progress />;
  if (!dosar.Id) return <h5 className="m-5 text-primary">Se încarcă dosarul ...</h5>;
  return <LivrareFacturaList />;
};

export default DosarScanPage;
