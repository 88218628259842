const Phone = ({children=""}) => {
  return (
    <a href={`tel:${children}`}>
      <i className="fa-duotone fa-phone-volume mx-2"></i>
      {children}
    </a>
  );
};

export default Phone;
