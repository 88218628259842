const Modal = ({ open = false, children = <></>, handleClose = () => { } }) => {
  return (
    <dialog open={open}>
      <article>
        <header>
          <a
            href="/"
            aria-label="Close"
            className="close"
            onClick={e => [e.preventDefault(), handleClose()]}
          >
          </a>
        </header>
        {children}
      </article>
    </dialog>
  );
};

export default Modal;
