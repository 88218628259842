import {
  ChangeEvent,
  SyntheticEvent,
  useEffect,
  useRef,
  useState,
} from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import LoginContainer from "../../components/LoginContainer";
import Input from "../../components/Input";
import ErrorBox from "../../components/ErrorBox";
import Title from "../../components/Title";
import { SqlError } from "../../common/sql";
import { useUser } from "../../hooks/useUser";
import { LoginInfo, loginUser, logoutUser } from "../../common/user";
import EmbeddedScannerSwitch from "../../components/EmbeddedScannerSwitch";

const LoginPage = () => {
  const { redirect, status } = useParams();
  const navigate = useNavigate();
  const { user, setUser } = useUser();

  const [error, setError] = useState<SqlError>();
  const [model, setModel] = useState<LoginInfo>({
    email: "",
    password: "",
  });
  const expiredSession = useRef(false);
  useEffect(() => {
    expiredSession.current = status === "401" && !!user.email;
    if (user.email) logoutUser().then(setUser);
  }, [setUser, expiredSession, status, user.email]);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setModel((model) => ({ ...model, [e.target.name]: e.target.value }));
    setError(undefined);
  };

  const handleSubmit = (e: SyntheticEvent) => {
    e.preventDefault();
    loginUser(model)
      .then(() => {
        setUser();
        navigate(decodeURIComponent(redirect || "/"));
      })
      .catch(setError);
  };

  return (
    <LoginContainer>
      <form>
        <Title icon="fa-user-unlock">Autentificare</Title>
        {expiredSession.current && (
          <p>
            <small style={{ color: "orangered" }}>
              Sesiunea ta a expirat, trebuie să te conectezi din nou cu email și
              parolă
            </small>
          </p>
        )}
        <Input
          type="email"
          name="email"
          required
          value={model.email}
          onChange={handleChange}
        >
          Adresa de email
        </Input>
        <Input
          type="password"
          name="password"
          required
          value={model.password}
          onChange={handleChange}
        >
          Parola
        </Input>
        <EmbeddedScannerSwitch />
        <ErrorBox>{error}</ErrorBox>

        <button type="submit" onClick={handleSubmit}>
          Conectare
        </button>
      </form>
      <p className="justify-content-between">
        <small>
          <Link to="/password-recover"> Ai uitat parola ?</Link>
        </small>
      </p>
      <p>
        <small>
          Nu te-ai înregistrat ?<Link to="/register"> Crează-ți un cont</Link>
        </small>
      </p>
    </LoginContainer>
  );
};

export default LoginPage;
