import React, { useState } from 'react'
import useLocalCache from '../common/localCache'
import { LivratIcon } from './livrare/StatusIcons';
import ProcesVerbalStatus from './livrare/ProcesVerbalStatus';
import { DosarEntity, FacturaEntity } from '../../../backend/src/shared/entity';
import ProcesVerbalSnapshot from './ProcesVerbalSnapshot';

const DosarOfflineLivrat = () => {
    const { dosar, colete } = useLocalCache();
    const [procesVerbal, setProcesVerbal] = useState<DosarEntity | FacturaEntity | undefined>();

    const numarPaleti = new Set(colete.filter(c => c.IndexPalet).map(c => c.IndexPalet)).size;
    if(procesVerbal) return <ProcesVerbalSnapshot {...{ procesVerbal, setProcesVerbal }} />
    return (
        <div className='mt-4'>
            <h5 className='mb-1'>Dosarul "{dosar.Nume}"</h5>
            <ProcesVerbalStatus procesVerbal={dosar} setProcesVerbal={setProcesVerbal} />
            <aside>
                <nav>
                    <ul className='text-bold'>
                        <li><h2 className='mb-2'>Livrare completă <LivratIcon /> </h2></li>
                        <li><hr /></li>
                        <DosarOfflineLivratDetail value={dosar.NumarColete} detail='colete total' />
                        {dosar.ScanatLiber && <DosarOfflineLivratDetail value={dosar.ScanatLiber} detail='colete libere' />}
                        {dosar.NumarPaleti && <DosarOfflineLivratDetail value={dosar.ScanatPalet} detail={<>colete pe <span className='text-larger text-success'>{numarPaleti}</span> paleți</>} />}
                    </ul>
                </nav>
            </aside>
        </div>
    )
}

export default DosarOfflineLivrat

const DosarOfflineLivratDetail = ({ value = 0, detail = <></> as string | React.ReactNode }) => {
    return (
        <li>
            <span className='d-inline-block text-end text-larger' style={{ minWidth: '2rem' }}>{value}</span> » {detail}
        </li>
    )
}