const LayoutSamplePage = () => {
  return (
    <main>
      <div className="row">
        <div className="col-md-5">
          <form>
            {/* Grid */}
            <div className="grid">
              {/* Markup example 1: input is inside label */}
              <label>
                First name
                <input
                  type="text"
                  name="firstname"
                  placeholder="First name"
                  required
                  aria-invalid="false"
                />
              </label>

              <label htmlFor="lastname">
                Last name
                <input
                  type="text"
                  id="lastname"
                  name="lastname"
                  placeholder="Last name"
                  required
                  aria-invalid="true"
                />
              </label>
            </div>

            {/* Markup example 2: input is after label */}
            <label htmlFor="email">Email address</label>
            <input
              type="email"
              id="email"
              name="email"
              placeholder="Email address"
              required
            />
            <small>We'll never share your email with anyone else.</small>

            {/* Select */}
            <label htmlFor="fruit">Fruit</label>
            <select id="fruit" required>
              <option value="" selected>
                Select a fruit…
              </option>
              <option>…</option>
            </select>

            {/* Radios */}
            <fieldset>
              <legend>Size</legend>
              <label htmlFor="small">
                <input
                  type="radio"
                  id="small"
                  name="size"
                  value="small"
                  checked
                />
                Small
              </label>
              <label htmlFor="medium">
                <input type="radio" id="medium" name="size" value="medium" />
                Medium
              </label>
              <label htmlFor="large">
                <input type="radio" id="large" name="size" value="large" />
                Large
              </label>
              <label htmlFor="extralarge">
                <input
                  type="radio"
                  id="extralarge"
                  name="size"
                  value="extralarge"
                  disabled
                />
                Extra Large
              </label>
            </fieldset>

            {/* Checkboxes */}
            <fieldset>
              <label htmlFor="terms">
                <input type="checkbox" id="terms" name="terms" />I agree to the
                Terms and Conditions
              </label>
              <label htmlFor="terms_sharing">
                <input
                  type="checkbox"
                  id="terms_sharing"
                  name="terms_sharing"
                  disabled
                  checked
                />
                I agree to share my information with partners
              </label>
            </fieldset>

            {/* Switches */}
            <fieldset>
              <label htmlFor="switch">
                <input
                  type="checkbox"
                  id="switch"
                  name="switch"
                  role="switch"
                />
                Publish on my profile
              </label>
            </fieldset>

            {/* Button */}
            <button type="submit" className="bg-dark">
              Submit
            </button>
          </form>
        </div>
        <div className="col-md-7">
          <figure>
            <table>
              <thead>
                <tr>
                  <th scope="col">#</th>
                  <th className="text-center" scope="col">
                    Heading
                  </th>
                  <th className="text-right" scope="col">
                    Heading
                  </th>
                  <th scope="col">Heading</th>
                  <th scope="col">Heading</th>
                  <th scope="col">Heading</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th scope="row">1</th>
                  <td className="text-center">Cell</td>
                  <td className="text-right">Cell</td>
                  <td>Cell</td>
                  <td>Cell</td>
                  <td>Cell</td>
                </tr>
                <tr>
                  <th scope="row">1</th>
                  <td>Cell</td>
                  <td>Cell</td>
                  <td>Cell</td>
                  <td>Cell</td>
                  <td>Cell</td>
                </tr>
                <tr>
                  <th scope="row">1</th>
                  <td>Cell</td>
                  <td>Cell</td>
                  <td>Cell</td>
                  <td>Cell</td>
                  <td>Cell</td>
                </tr>
              </tbody>
            </table>
          </figure>
        </div>
      </div>
    </main>
  );
};

export default LayoutSamplePage;
