function subscribe(eventName: EventName | EventName[], listener: (data: any) => void) {
  if (!Array.isArray(eventName)) {
    eventName = [eventName];
  }
  eventName.forEach((name) => {
    document.addEventListener(name, listener);
  });

}

function unsubscribe(eventName: EventName, listener: (data: any) => void) {
  document.removeEventListener(eventName, listener);
}

function publish(eventName: EventName, data: any = "") {
  const event = new CustomEvent(eventName, { detail: data });
  setTimeout(() => document.dispatchEvent(event));
}

export { publish, subscribe, unsubscribe };

export type EventName =
  "ColetChanged" |
  "DosarChanged" |
  "FacturaChanged" |
  "ScanningChanged" |
  "ReturScanComplet" |
  "PaletScanComplet" |
  "FacturaScanComplet" |
  "CamionPaletComplet" |
  "DialogMessageChanged" |
  "CameraScanningChanged" |
  "ProcesVerbalSnapshot" |
  "CameraSnapshotChanged" |
  "localCacheChanged";