import { useState } from "react";
import { getCollection, setQueue } from "../../common/localCache";
import { Link } from "react-router-dom";
const playBeep = () => document.querySelector("audio")?.play();

const CamionRefuzOptionPage = () => {
  const motiveRetur = getCollection("dbo.MotivRetur");
  const facturi = getCollection("dbo.Factura").filter(
    (f) => f.NumarColete > f.ScanatLivrat + f.ScanatRefuz
  );
  const [idFactura, setIdFactura] = useState("");
  const [tipRefuz, setTipRefuz] = useState<"Colet" | "Factura" | "">("");
  const [motivRefuz, setMotivRefuz] = useState("");

  if (facturi.length === 0)
    return (
      <div className="alert text-warning py-4">
        Nu există livrări/facturi care să poată fi marcate "refuzat"!
      </div>
    );

  const handleRefuzLivrareFactura = () => {
    if (!idFactura) return;
    const factura = facturi.find((f) => f.Id === +idFactura);
    if (!factura) return alert("Factura nu a fost găsită!");
    setQueue("dbo.Factura", {
      ...factura,
      MotivRetur: motivRefuz,
      ScanatRefuz: factura.NumarColete,
      ScanatLivrat: 0,
    });
    getCollection("dbo.Colet")
      .filter((c) => c.IdFactura === factura.Id)
      .forEach((c) =>
        setQueue("dbo.Colet", {
          ...c,
          ScanatLivrat: null,
          ScanatRefuz: new Date(),
          MotivRetur: motivRefuz,
        })
      );
  };

  return (
    <form className="mt-4">
      <label>
        <p className="text-larger">Refuz la livrare</p>
        <select
          id="idFactura"
          required
          onChange={(e) => [
            e.preventDefault(),
            setTipRefuz(""),
            setIdFactura(e.target.value),
          ]}
          defaultValue=""
        >
          <option value="">--Alege livrarea/factura--</option>
          {facturi.map((f) => (
            <option value={f.Id} key={f.Id}>
              {f.Document}, {f.Cumparator}, {f.NumarColete} colete
            </option>
          ))}
        </select>
      </label>

      {idFactura && (
        <label>
          Tip refuz
          <select
            id="tipRefuz"
            required
            onChange={(e) => [
              e.preventDefault(),
              setTipRefuz(e.target.value as "Colet" | "Factura"),
              setMotivRefuz(""),
            ]}
            value={tipRefuz}
          >
            <option value="">--Alege tipul de refuz--</option>
            <option value="Factura">Refuz pentru întrega livrare</option>
            <option value="Colet">Scanez colet(e) refuzat(e)</option>
          </select>
        </label>
      )}

      {tipRefuz && (
        <label>
          Motiv refuz
          <select
            id="motivRefuz"
            required
            onChange={(e) => [
              e.preventDefault(),
              setMotivRefuz(e.target.value),
            ]}
            value={motivRefuz}
          >
            <option value="">--Alege motiv refuz--</option>
            {motiveRetur.map((m) => (
              <option key={m.Id} value={m.Nume}>
                {m.Nume}
              </option>
            ))}
          </select>
        </label>
      )}
      <p>
        <Link to="/home" className="outline text-warning float-start">
          Renunță
        </Link>
        {motivRefuz && (
          <>
            {tipRefuz === "Colet" && (
              <Link
                to={`/camion-refuz/${idFactura}/${motivRefuz}`}
                className="outline float-end"
                onClick={playBeep}
              >
                <i className="fa-lg fa-fw fa-regular fa-barcode-read"></i>{" "}
                Scanare colet(e)
              </Link>
            )}
            {tipRefuz === "Factura" && (
              <Link
                to="/home"
                className="outline float-end"
                onClick={handleRefuzLivrareFactura}
              >
                <i className="fa-lg fa-fw fa-light fa-hand text-danger"></i>{" "}
                Refuză livrarea
              </Link>
            )}
          </>
        )}
      </p>
    </form>
  );
};

export default CamionRefuzOptionPage;
