import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import sql from "../../common/sql";
import { DosarEntity } from "../../../../backend/src/shared/entity";

const BarcodePaletiPage = () => {
  const { idDosar } = useParams();
  const [dosar, setDosar] = useState({} as DosarEntity);
  const [codes, setCodes] = useState<Array<Model>>([]);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetch(`/api/paletiQrCode/${idDosar}`)
      .then((result) => result.json())
      .then(setCodes)
      .catch(setError)
      .finally(() => setLoading(false));
    sql
      .dosarByIdQuery({ idDosar })
      .then(([dosar]) => setDosar(dosar))
      .catch(setError);
  }, [idDosar, setCodes]);

  if (loading) return <progress></progress>;
  if (error) return <h4>{error}</h4>;
  return (
    <>
      <div className="row">
        <h4>{dosar.Nume}</h4>
        <div className="row">
          <p className="text-larger">Colete libere</p>
          {codes
            .filter((code) => code.Ambalare === "Colet")
            .map((code) => (
              <div key={code.IdColet} className="col-6 col-lg-4 col-xl-3 mb-4">
                <img
                  src={code.BarCode}
                  alt="BarCode"
                  style={{ background: "white", padding: "1rem" }}
                />
                {code.IdColet}
              </div>
            ))}
          <p className="text-larger">Paleți</p>
          {codes
            .filter((code) => code.Ambalare === "Palet")
            .map((code) => (
              <div key={code.IdColet} className="col-6 col-lg-4 col-xl-3 mb-2">
                <img
                  src={code.QrCode}
                  alt="QrCode"
                  style={{ background: "white", padding: "1rem" }}
                />
                <p className="mt-1">
                  Palet {code.IndexPalet} ({code.NumarColete} colete) <br />
                  {code.ScanCode}
                </p>
              </div>
            ))}
        </div>
      </div>
    </>
  );
};

export default BarcodePaletiPage;

type Model = {
  IdColet: number;
  Ambalare: string;
  IndexPalet: number;
  NumarColete: number;
  QrCode: string;
  BarCode: string;
  ScanCode: string;
};
