export const CompleteIcon = () => (
    <i className="fa-fw fa-solid fa-check-double"></i>
  );
  
  export const ReturIcon = () => (
    <i className="text-success fa-rotate-270 fa-fw fa-duotone fa-arrows-retweet"></i>
  );
  
  export const RefuzIcon = () => (
    <i className="text-danger fa-fw fa-light fa-hand"></i>
  );
  
  export const LivratIcon = () => (
    <i className="text-success fa-fw fa-solid fa-box-check"></i>
  );
  
  export const CamionIcon = () => (
    <i className="text-primary fa-fw fa-duotone fa-truck-fast"></i>
  );
  
  export const PaletIcon = () => (
    <i className="text-success fa-fw fa-light fa-pallet-boxes"></i>
  );
  
  export const LiberIcon = () => (
    <i className="text-warning fa-fw fa-light fa-box"></i>
  );
  
  export const DepozitIcon = () => (
    <i className="text-warning fa-fw fa-regular fa-shop"></i>
  );
  