let DDD = ["Duminică", "Luni", "Marți", "Miercuri", "Joi", "Vineri", "Sâmbătă"];
let DD = ["Dum", "Lun", "Mar", "Mie", "Joi", "Vin", "Sâm"];

let MMM = ["Ianuarie", "Februarie", "Martie", "Aprilie", "Mai", "Iunie",
    "Iulie", "August", "Septembrie", "Octombrie", "Noiembrie", "Decembrie"];
let MM = ["Ian", "Feb", "Mar", "Apr", "Mai", "Iun", "Iul", "Aug", "Sep", "Oct", "Noi", "Dec"];

export function formatDate(value: null | string | Date, format: "short" | "long" | "text" = "short") {
    if (!value) return "";
    let date = value instanceof Date ? value : new Date(value);
    return format === "short" ?
        date.toLocaleDateString() :
        format === "long" ?
            date.toLocaleString() : textOfDate(date);
}

export function textOfDate(value: Date | string, format = "DDD, dd MM YYYY"): string {
    if (!value) return "";
    let date = value instanceof Date ? value : new Date(value);
    let totay = new Date();
    let yesterday = new Date(totay.getFullYear(), totay.getMonth(), totay.getDate() - 1);
    let tomorrow = new Date(totay.getFullYear(), totay.getMonth(), totay.getDate() + 1);
    if (date.toDateString() === totay.toDateString()) format = format.replace('DDD', "azi").replace('DD', "azi");
    if (date.toDateString() === yesterday.toDateString()) format = format.replace('DDD', "ieri").replace('DD', "ieri");
    if (date.toDateString() === tomorrow.toDateString()) format = format.replace('DDD', "mâine").replace('DD', "mâine");
    let day = date.getDate();
    let month = date.getMonth();
    let year = date.getFullYear();
    format = format
        .replace("DDD", DDD[date.getDay()]).replace("DD", DD[date.getDay()])
        .replace("MMMM", MMM[month]).replace("MM", MM[month])
        .replace("YYYY", year.toString()).replace("YY", year.toString().substring(2))
        .replace("dd", day.toString())
        .replace("M.", month.toString()+'.')
        .replace("M/", month.toString()+'/')
        .replace("M-", month.toString()+'-')
        .replace("M ", month.toString()+' ')
        .replace("Y", year.toString());
    return format;
}

export function cacheStaticFiles() {
    [
        "/audio/scanner-beep.mp3",
        "/audio/scanner-error.mp3",
        "/webfonts/fa-brands-400.ttf",
        "/webfonts/fa-brands-400.ttf",
        "/webfonts/fa-brands-400.woff2",
        "/webfonts/fa-duotone-900.ttf",
        "/webfonts/fa-duotone-900.woff2",
        "/webfonts/fa-light-300.ttf",
        "/webfonts/fa-light-300.woff2",
        "/webfonts/fa-regular-400.ttf",
        "/webfonts/fa-regular-400.woff2",
        "/webfonts/fa-sharp-light-300.ttf",
        "/webfonts/fa-sharp-light-300.woff2",
        "/webfonts/fa-sharp-regular-400.ttf",
        "/webfonts/fa-sharp-regular-400.woff2",
        "/webfonts/fa-sharp-solid-900.ttf",
        "/webfonts/fa-sharp-solid-900.woff2",
        "/webfonts/fa-solid-900.ttf",
        "/webfonts/fa-solid-900.woff2",
        "/webfonts/fa-thin-100.ttf",
        "/webfonts/fa-thin-100.woff2",
        "/webfonts/fa-v4compatibility.ttf",
        "/webfonts/fa-v4compatibility.woff2",
    ].forEach(resource => fetch(resource).then(console.log).catch(console.error));
}