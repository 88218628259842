import React from 'react'
import { BrowserRouter, Routes, Route } from "react-router-dom";
import HomePage from "../pages/HomePage";
import RegisterPage from "../pages/user/RegisterPage";
import Navbar from "./navbar/Navbar";
import LayoutSamplePage from "../pages/sample/LayoutSamplePage";
import PasswordRecoverPage from "../pages/user/PasswordRecoverPage";
import PasswordResetPage from "../pages/user/PasswordResetPage";
import DosarDetailsPage from "../pages/dosar/DosarDetailsPage";
import ConfigPage from "../pages/user/ConfigPage";
import ValidateEmailPage from "../pages/user/ValidateEmailPage";
import DosarIndexPage from "../pages/dosar/DosarIndexPage";
import BarcodeFacturiPage from "../pages/sample/BarcodeFacturiPage";
import DosarScanPage from "../pages/camion/DosarScanPage";
import ScannerSamplePage from "../pages/sample/ScannerSamplePage";
import BarcodeColetePage from "../pages/sample/BarcodeColetePage";
import CamionRefuzOptionPage from "../pages/camion/CamionRefuzOptionPage";
import ColeteStatusReset from "../pages/colet/ColeteStatusReset";
import DosarEditPage from "../pages/dosar/DosarEditPage";
import DepozitPaletPage from "../pages/depozit/DepozitPaletPage";
import DepozitReturPage from "../pages/depozit/DepozitReturPage";
import CamionPaletPage from "../pages/camion/CamionPaletPage";
import BarcodePaletiPage from "../pages/sample/BarcodePaletiPage";
import CamionColetPage from "../pages/camion/CamionColetPage";
import CamionLivratPage from "../pages/camion/CamionLivratPage";
import CamionRefuzPage from "../pages/camion/CamionRefuzPage";
import CamionDecontPage from "../pages/camion/CamionDecontPage";
import SumarArticoleDosare from "../pages/raport/SumarArticoleDosare";
import TestPage from "../pages/sample/TestPage";
import LoginSofer from "./LoginSofer";
import LoginPage from '../pages/user/LoginPage';
import { Auth } from '../App';

const Router = () => {
  const navigateBack = -1 as any as string;
  return (
    <BrowserRouter>
    <audio src="/audio/scanner-beep.mp3"></audio>
    <Navbar />
    <Routes>
      <Route index element={<HomePage />} />
      <Route path="/home" element={<HomePage />} />
      <Route path="/test" element={<TestPage />} />
      <Route path="/login" element={<LoginPage />} />
      <Route path="/login-sofer" element={<LoginSofer navigateTo={navigateBack} />} />
      <Route path="/register" element={<RegisterPage />} />
      <Route
        path="/password-recover"
        element={<PasswordRecoverPage />}
      />
      <Route
        path="/password-reset/:token"
        element={<PasswordResetPage />}
      />
      <Route
        path="/validate-email/:token"
        element={<ValidateEmailPage />}
      />
      <Route path="/config" element={<ConfigPage />} />
      <Route
        path="/colete-status-reset/:idFactura"
        element={<ColeteStatusReset />}
      />
      <Route
        path="/depozit-palet"
        element={Auth(<DepozitPaletPage />)}
      />
      <Route
        path="/depozit-retur"
        element={Auth(<DepozitReturPage />)}
      />
      <Route
        path="/camion-refuz-option"
        element={<CamionRefuzOptionPage />}
      />
      <Route
        path="/camion-refuz/:idFactura/:motivRefuz?"
        element={<CamionRefuzPage />}
      />
      <Route
        path="/camion-livrat/:scanning?"
        element={<CamionLivratPage />}
      />
      <Route
        path="/camion-colet/:scanning?"
        element={<CamionColetPage />}
      />
      <Route
        path="/camion-palet/:paletQR?/:scanning?"
        element={<CamionPaletPage />}
      />
      <Route path="/camion-decont" element={<CamionDecontPage />} />

      <Route path="/dosar-index" element={Auth(<DosarIndexPage />)} />
      <Route
        path="/dosar-edit/:idDosar?"
        element={Auth(<DosarEditPage />)}
      />
      <Route
        path="/dosar-details/:idDosar"
        element={Auth(<DosarDetailsPage />)}
      />
      <Route
        path="/dosar-scan/:idDosar/:paletQR?"
        element={<DosarScanPage />}
      />
      <Route
        path="/barcode-facturi"
        element={Auth(<BarcodeFacturiPage />)}
      />
      <Route
        path="/barcode-paleti/:idDosar"
        element={Auth(<BarcodePaletiPage />)}
      />
      <Route
        path="/barcode-colete/:idDosar"
        element={Auth(<BarcodeColetePage />)}
      />
      <Route path="/layout-sample" element={<LayoutSamplePage />} />
      <Route path="/scanner-sample" element={<ScannerSamplePage />} />
      <Route
        path="/raport/sumar-articole-dosar"
        element={Auth(<SumarArticoleDosare />)}
      />
    </Routes>
  </BrowserRouter>
)
}

export default Router