import { DosarEntity, FacturaEntity } from "../../../../backend/src/shared/entity";
import {
  CamionIcon,
  CompleteIcon,
  DepozitIcon,
  LivratIcon,
  PaletIcon,
  RefuzIcon,
} from "./StatusIcons";

export const StatusLivrare = ({
  ScanatPalet,
  ScanatLiber,
  ScanatCamion,
  ScanatLivrat,
  ScanatRefuz,
  NumarColete,
}: DosarEntity | FacturaEntity) => {
  if (ScanatRefuz)
    return (
      <>
        {ScanatLivrat > 0 && (
          <>
            <LivratIcon /> {"livrat "}
            <Progress done={ScanatLivrat} total={NumarColete} /> <br />
          </>
        )}
        <RefuzIcon /> refuz <Progress done={ScanatRefuz} total={NumarColete} />
      </>
    );
  if (ScanatLivrat)
    return (
      <>
        <LivratIcon /> {"livrat "}
        <Progress done={ScanatLivrat} total={NumarColete} />
      </>
    );
  if (ScanatCamion)
    return (
      <>
        <CamionIcon /> {"camion "}
        <Progress done={ScanatCamion} total={NumarColete} />
      </>
    );
  if (ScanatPalet)
    return (
      <>
        <PaletIcon /> {"palet "}
        <Progress done={ScanatPalet + ScanatLiber} total={NumarColete} />
      </>
    );
  return (
    <>
      <DepozitIcon /> depozit {NumarColete}/{NumarColete}
    </>
  );
};

export const Progress = ({
  total = 0 as number | boolean,
  done = 0 as number | string,
}) => {
  if (typeof done === "string") {
    total = parseInt(done.split("/")[1]);
    done = parseInt(done.split("/")[0]);
  }
  if (!total) return <span className="text-muted"></span>;
  // if (done === total) return <CompleteIcon />;
  return (
    <span className={done === total ? "text-success" : "text-warning-"}>
      {done}/{total} {done === total && <CompleteIcon />}
    </span>
  );
};
