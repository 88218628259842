import { Link } from "react-router-dom";
import { DosarEntity, FacturaEntity } from "../../../../backend/src/shared/entity";

const ProcesVerbalStatus = ({
  procesVerbal = {} as FacturaEntity | DosarEntity,
  setProcesVerbal = (entity: FacturaEntity | DosarEntity) => { },
}) => {
  const { NumarColete, ScanatLivrat, ScanatRefuz, ProcesVerbal } = procesVerbal;
  if ((procesVerbal as FacturaEntity).Document && !(ScanatLivrat > 0 && ScanatLivrat + ScanatRefuz === NumarColete))
    return <div></div>;
  return (
    <span>
      <Link
        className={ProcesVerbal ? "text-muted" : "text-warning"}
        to="/"
        onClick={(e) => [e.preventDefault(), setProcesVerbal(procesVerbal)]}
      >
        {ProcesVerbal ? "P.V. încărcat" : "P.V. lipsă"}
        <i
          className={`ms-2 fa-lg fa-solid ${ProcesVerbal
            ? "fa-check-double text-success"
            : "fa-xl fa-circle-camera"
            }`}
        ></i>
      </Link>
    </span>
  );
};

export default ProcesVerbalStatus;