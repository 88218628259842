import { useState } from "react";
import useLocalCache from "../common/localCache";
import { DosarEntity, FacturaEntity } from "../../../backend/src/shared/entity";

const useProcesVerbal = () => {
  const { dosar, facturi } = useLocalCache();
  const [procesVerbal, setProcesVerbal] = useState<DosarEntity | FacturaEntity | undefined>(dosar.Offline ? undefined : getProcessVerbal());

  if (!procesVerbal && !dosar.Offline && getProcessVerbal()) setProcesVerbal(getProcessVerbal());

  function getProcessVerbal() {
    return facturi.find(f =>
      f.ScanatLivrat + f.ScanatRefuz === f.NumarColete &&
      !f.ProcesVerbal &&
      f.ScanatLivrat
    );
  }

  return { procesVerbal, setProcesVerbal };
}

export default useProcesVerbal;
