import { FacturaEntity } from "../../../backend/src/shared/entity";

const Ramburs = ({
  NumarColete,
  ScanatRefuz,
  Valoare,
  AchitatOnline
}:FacturaEntity) => {
  if (AchitatOnline)
    return (
      <span className="text-center">
        <p className="mb-1">
          <i className="fa-duotone fa-credit-card"></i>
        </p>
        <p className="mb-0 text-small text-muted">Achitat</p>
        <p className="mb-0 text-small text-muted">Online</p>
      </span>
    );
  const ramburs = NumarColete === ScanatRefuz ? 0 : Valoare;
  return (
    <span className="text-center">
      <p className="mb-1">
        <i className="fa-xl fa-light fa-sack-dollar text-danger"></i>
      </p>
      <p className="mb-0 text-small">{ramburs.toFixed(2)} lei</p>
      <p className="mb-0 text-small">Ramburs</p>
    </span>
  );
};

export default Ramburs;
