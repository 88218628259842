import { ChangeEvent, SyntheticEvent, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { LoginInfo, loginUser } from "../common/user";
import { useUser } from "../hooks/useUser";
import Input from "../components/Input";
import ErrorBox from "../components/ErrorBox";
import Title from "../components/Title";
import { SqlError } from "../common/sql";
import { cacheStaticFiles } from "../common/utils";
import EmbeddedScannerSwitch from "./EmbeddedScannerSwitch";

const LoginSofer = ({ navigateTo = "", handleLogin = (): void => void 0 }) => {
  const navigate = useNavigate();
  const { setUser } = useUser();
  const [error, setError] = useState<SqlError>();
  const [model, setModel] = useState<LoginInfo>(
    JSON.parse(
      localStorage.getItem("__sofer__") ||
      `{ "email": "", "auto": "" , "role": "sofer"}`
    )
  );
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setModel((model) => ({
      ...model,
      [e.target.name]:
        e.target.name === "auto"
          ? e.target.value.toUpperCase()
          : e.target.value,
    }));
    setError(undefined);
  };

  const handleSubmit = (e: SyntheticEvent) => {
    e.preventDefault();
    loginUser(model)
      .then(() => {
        cacheStaticFiles();
        localStorage.setItem("__sofer__", JSON.stringify(model));
        setUser();
        navigateTo && navigate(navigateTo);
        handleLogin && handleLogin();
      })
      .catch(setError);
  };

  return (
    <form>
      <span className="float-end">
        <Link to="/login" className="d-inline w-auto">
          <i className="text-warning fa-xl fa-solid fa-arrow-right-from-arc"></i>
        </Link>
      </span>
      <Title icon="fa-user-unlock">Autentificare</Title>
      <Input
        type="text"
        name="email"
        required
        value={model.email}
        onChange={handleChange}
      >
        Nume și prenume
      </Input>
      <Input
        type="text"
        name="auto"
        required
        value={model.auto}
        onChange={handleChange}
      >
        Număr auto
      </Input>
      <EmbeddedScannerSwitch />
      <ErrorBox>{error}</ErrorBox>
      <button type="submit" onClick={handleSubmit}>
        Salvează
      </button>
    </form>
  );
};

export default LoginSofer;
