import { useQuery } from "@tanstack/react-query";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import sql, { SqlError } from "../../common/sql";
import Fa from "../../components/FontAwesome";
import ErrorBox from "../../components/ErrorBox";

const SumarArticoleDosare = () => {
  const [model, setModel] = useState({
    startDate: new Date(),
    endDate: new Date(),
  });
  const [error, setError] = useState<SqlError | null>();
  const [data, setData] = useState([]);

  useQuery({
    queryKey: ["DosarDetails", model],
    queryFn: ({ queryKey: [, model] }) =>
      sql.sumarArticoleDosareReportQuery(model as any),
    onSuccess: setData,
    onError: setError,
  });

  const reportUrl = (
    template: string,
    params: { [key: string]: number | string | Date }
  ) =>
    "/api/report/" +
    btoa(
      JSON.stringify({
        template,
        params,
      })
    );
  if (error) return <ErrorBox children={error} />;
  return (
    <div>
      <h1>Sumar articole dosare</h1>
      <table>
        <thead>
          <tr className="borderless">
            <td>
              <label htmlFor="startDate">
                Data început
                <input
                  type="date"
                  name="startDate"
                  id="startDate"
                  className="form-control"
                  value={model.startDate.toISOString().substring(0, 10)}
                  onChange={(e) =>
                    setModel({ ...model, startDate: new Date(e.target.value) })
                  }
                />
              </label>
            </td>
            <td>
              <label htmlFor="endDate">
                Data sfârșit
                <input
                  type="date"
                  name="endDate"
                  id="endDate"
                  className="form-control"
                  value={model.endDate.toISOString().substring(0, 10)}
                  onChange={(e) =>
                    setModel({ ...model, endDate: new Date(e.target.value) })
                  }
                />
              </label>
            </td>
          </tr>
          <tr>
            <td colSpan={2} className="text-end">
              <Link
                to={reportUrl("SumarArticoleDosare", model)}
                target="_blang"
              >
                <Fa duotone="file-spreadsheet text-success" /> Salvează ca excel
              </Link>
            </td>
          </tr>
        </thead>
      </table>
      <table>
        <tbody>
          {data.map((row: any) => (
            <>
              {row === data[0] && (
                <tr key={0}>
                  {Object.keys(row).map((key) => (
                    <th key={key}>{key}</th>
                  ))}
                </tr>
              )}
              <tr key={row.COD_SENIOR}>
                {Object.keys(row).map((key) => (
                  <td key={key}>{row[key]}</td>
                ))}
              </tr>
            </>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default SumarArticoleDosare;
