import { useEffect, useState } from "react";
import ScannerWebcam from "../../components/scanner/ScannerWebcam";
import LivrareFacturaList from "../../components/livrare/LivrareFacturaList";
import ScannerInput from "../../components/scanner/ScannerInput";
import { publish, subscribe } from "../../common/events";
import useModal from "../../hooks/useModal";
import CamionRefuzConsumer from "../../components/consumer/CamionRefuzConsumer";
import { useNavigate, useParams } from "react-router-dom";
import { getCollection } from "../../common/localCache";
import { useScanner } from "../../hooks/useScanner";

const CamionRefuzPage = () => {
  const { idFactura = 0 } = useParams();
  const { embeddedScanner } = useScanner();
  const [isIdValid] = useState(
    !!getCollection("dbo.Factura").find((f) => f.Id === +idFactura)
  );
  const [barcode, setBarcode] = useState("");
  const [isScanning, setIsScanning] = useState(isIdValid && !embeddedScanner);
  const [PVSnapshot, setPVSnapshot] = useState(false);
  const modal = useModal();
  const navigate = useNavigate();

  subscribe("CameraScanningChanged", (e) => [
    setBarcode(""),
    setIsScanning(e.detail),
  ]);

  subscribe("ProcesVerbalSnapshot", (e) => [
    setBarcode(""),
    setPVSnapshot(e.detail),
  ]);

  subscribe("FacturaScanComplet", (e) => [
    publish("CameraScanningChanged", false),
    modal(e.detail, "Scanare completă"),
    navigate("/camion-livrat"),
  ]);

  useEffect(() => {
    if (!isIdValid) {
      modal(
        <span className="text-danger">
          Factura cu id-ul {idFactura} nu a fost găsită!
        </span>,
        "Eroare"
      );
      navigate("/");
    }
  }, [isIdValid, idFactura, navigate, modal]);

  return (
    <>
      {isScanning ? (
        <ScannerWebcam
          {...{
            handleSearch: setBarcode,
            isScanning,
          }}
        >
          <CamionRefuzConsumer barcode={barcode} />
        </ScannerWebcam>
      ) : (
        PVSnapshot || (
          <div className="row">
            <CamionRefuzConsumer barcode={barcode} />
            <ScannerInput {...{ handleSearch: setBarcode }} />
          </div>
        )
      )}
      <LivrareFacturaList />
    </>
  );
};

export default CamionRefuzPage;
