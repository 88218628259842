import { useState } from "react";
import { getCollection } from "../../common/localCache";
import ValoareDecontFactura from "../../components/livrare/ValoareDecontFactura";
import { StatusLivrareIcon } from "../../components/livrare/StatusLivrareIcon";
import { Link } from "react-router-dom";

const CamionDecontPage = () => {
  const [facturi] = useState(getCollection("dbo.Factura"));
  const [colete] = useState(getCollection("dbo.Colet"));
  const [detailsId, setDetailsId] = useState(0);
  const ramburs = facturi
    .filter((f) => !(f.AchitatOnline || f.ScanatRefuz === f.NumarColete))
    .reduce((acc, f) => {
      return acc + f.Valoare;
    }, 0);

  return (
    <>
      <table>
        <thead>
          <tr className="text-larger">
            <th>Total Rambus/Depunere</th>
            <td className="text-bold text-end">{ramburs} lei</td>
          </tr>
        </thead>
        <tbody>
          {facturi.map((f) => {
            return (
              <tr key={f.Id}>
                <td colSpan={2}>
                  <div className="justify-content-between">
                    <Link
                      to=""
                      onClick={(e) => [
                        e.preventDefault(),
                        setDetailsId((prev) => (prev === f.Id ? 0 : f.Id)),
                      ]}
                    >
                      <StatusLivrareIcon {...f} /> {f.Document}
                    </Link>
                    <div style={{ width: "7rem" }}>
                      <ValoareDecontFactura {...f} />
                    </div>
                  </div>
                  {f.Id === detailsId && (
                    <>
                      {colete
                        .filter((c) => c.IdFactura === f.Id)
                        .map((colet) => (
                          <p key={colet.Id} className="mb-0 mt-2 text-start">
                            <StatusLivrareIcon {...colet} /> {colet.Descriere}{" "}
                            {colet.ScanatRefuz &&
                              f.ScanatRefuz < f.NumarColete &&
                              <span className="text-warning"> ( {colet.MotivRetur} )</span>
                              }
                          </p>
                        ))}
                    </>
                  )}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </>
  );
};

export default CamionDecontPage;
