import { useState } from "react";
import { useUser } from "../hooks/useUser";
import useLocalCache from "../common/localCache";
import { Link } from "react-router-dom";
import { Progress } from "./livrare/StatusLivrare";
import { StatusLivrareIcon } from "./livrare/StatusLivrareIcon";
import { PaletIcon } from "./livrare/StatusIcons";
import { ColetEntity } from "../../../backend/src/shared/entity";
import OperareManualaStatusColet from "./livrare/OperareManualaStatusColet";

const CamionPaletProgress = () => {
  const { user } = useUser();
  const { dosar, colete } = useLocalCache();
  const [details, setDetails] = useState("" as "colete" | "paleti" | "");
  const onDetails = (value: "colete" | "paleti") =>
    setDetails((prev) => (prev === value ? "" : value));
  const [colet, setColet] = useState<null | ColetEntity>(null);
  const paleti = Array.from(
    new Set(colete.filter((c) => c.IndexPalet).map((c) => c.IndexPalet))
  ).map((c) => ({
    IndexPalet: c,
    NumarColete: colete.filter((p) => p.IndexPalet === c).length,
  }));

  const liberCount = colete.filter((c) => c.ScanatLiber).length || 0;
  const liberCamion =
    colete.filter((c) => c.ScanatCamion && c.ScanatLiber).length || 0;
  const paletCount =
    colete.filter(
      (val, idx, self) =>
        val.IndexPalet &&
        idx === self.findIndex((e) => e.IndexPalet === val.IndexPalet)
    ).length || 0;
  const paletCamion =
    colete.filter(
      (val, idx, self) =>
        val.IndexPalet &&
        val.ScanatCamion &&
        idx === self.findIndex((e) => e.IndexPalet === val.IndexPalet)
    ).length || 0;

  return (
    <>
      <h5>
        <div className="text-end">
          Dosar
          <Link
            className="ms-2"
            to={user.isSofer ? `` : `/dosar-details/${dosar?.Id}`}
          >
            {dosar?.Nume}
          </Link>
        </div>
        <hr />
        <span className="float-end">
          <Link
            to=""
            onClick={(e) => [e.preventDefault(), onDetails("paleti")]}
          >
            <Progress total={paletCount} done={paletCamion} /> paleți
          </Link>
        </span>
        {!!liberCount && (
          <Link
            to=""
            onClick={(e) => [e.preventDefault(), onDetails("colete")]}
          >
            <Progress total={liberCount} done={liberCamion} /> colete libere
          </Link>
        )}
      </h5>
      <div>
        {details === "colete" &&
          colete
            ?.filter((c) => c.ScanatLiber)
            .map((colet) => (
              <p key={colet.Id} className="mb-0 mt-2 text-start" onClick={()=>setColet(colet)}>
                <StatusLivrareIcon {...colet} /> {colet.Descriere}
              </p>
            ))}
        {details === "paleti" &&
          paleti?.map((p) => (
            <p key={p.IndexPalet} className="mb-0 mt-2 text-start">
              <PaletIcon /> Palet {p.IndexPalet}/{paleti.length}
              - {p.NumarColete} colete
            </p>
          ))}
      </div>
      <OperareManualaStatusColet colet={colet} setColet={setColet} etapa="livrare" />
    </>
  );
};

export default CamionPaletProgress;