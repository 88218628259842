import { publish } from "../common/events";

const modal = (
  content: JSX.Element | string | null = null,
  title: JSX.Element | string = "Informare",
  action?: JSX.Element | (() => void),
  onClose?: () => void
) => {
  publish("DialogMessageChanged", content && { content, title, action: (action instanceof Function) ? undefined : action, onClose: onClose || ((action instanceof Function) && action) });
};

const useModal = () => {
  return modal;
}

export default useModal;