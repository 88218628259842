import { ChangeEvent, SyntheticEvent, useState } from "react";
import Input from "../../components/Input";
import { RegisterInfo, registerUser } from "../../common/user";
import { Link, useNavigate } from "react-router-dom";
import LoginContainer from "../../components/LoginContainer";
import ErrorBox from "../../components/ErrorBox";
import Title from "../../components/Title";
import { SqlError } from "../../common/sql";

const RegisterPage = () => {
  const navigate = useNavigate();
  const [model, setModel] = useState<RegisterInfo>({
    name: "",
    email: "",
    password: "",
    passwordRepeat: "",
  });
  const [completed, setCompleted] = useState(false);
  const [error, setError] = useState<SqlError>();

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setModel((model) => ({ ...model, [e.target.name]: e.target.value }));
    setError(undefined);
  };

  const handleSubmit = (e: SyntheticEvent) => {
    e.preventDefault();
    registerUser(model)
      .then(() => {
        setCompleted(true);
      })
      .catch(setError);
  };

  return (
    <LoginContainer>
      {completed ? (
        <dialog id="modal-register" open={completed}>
          <article>
            <a
              href="/"
              aria-label="Close"
              className="close"
              data-target="modal-register"
              onClick={() => navigate("/")}
            > </a>
            <h4>
              <ins>
                <i className="fa-2xl fa-light fa-user-check me-3"></i>
              </ins>
              Cont creat cu succes !
            </h4>
            <p>
              Verifică căsuța de email <b>{model.email}</b> și folosește link-ul
              de activare primit. Verifică atât în folderul <b>inbox</b> cât și
              în folderul <b>spam</b>.
            </p>
            <footer>
              <a
                href="/"
                role="button"
                data-target="modal-register"
                onClick={() => navigate("/")}
              >
                Închide
              </a>
            </footer>
          </article>
        </dialog>
      ) : (
        <form>
          <Title icon="fa-user-plus">Crează cont nou</Title>
          <Input
            name="email"
            type="email"
            minLength={10}
            required
            value={model.email}
            onChange={handleChange}
          >
            Adresa de email
          </Input>

          <Input
            type="password"
            name="password"
            // pattern="^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$"
            required
            value={model.password}
            onChange={handleChange}
          >
            Crează o parolă
          </Input>

          <Input
            type="password"
            name="passwordRepeat"
            // pattern="^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$"
            required
            value={model.passwordRepeat}
            onChange={handleChange}
          >
            Repetă parola
          </Input>

          <Input
            type="text"
            name="name"
            required
            value={model.name}
            onChange={handleChange}
          >
            Nume și prenume
          </Input>

          <ErrorBox>{error}</ErrorBox>
          <button type="submit" onClick={handleSubmit}>
            Crează cont
          </button>
        </form>
      )}
      <p>
        <small>
          {" "}
          Ai deja cont ?<Link to="/login"> Autentifică-te</Link>
        </small>
      </p>
    </LoginContainer>
  );
};

export default RegisterPage;
