import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ScannerWebcam from "../../components/scanner/ScannerWebcam";
import LivrareFacturaList from "../../components/livrare/LivrareFacturaList";
import ScannerInput from "../../components/scanner/ScannerInput";
import { subscribe } from "../../common/events";
import { useScanner } from "../../hooks/useScanner";
import useLocalCache from "../../common/localCache";
import { toast } from "sonner";
import useModal from "../../hooks/useModal";
import { LivrareFacturaStatus } from "../../components/livrare/LivrareFacturaStatus";
import ErrorBox from "../../components/ErrorBox";

const CamionColetPage = () => {
  const { scanning = false } = useParams();
  const { embeddedScanner } = useScanner();
  const [isScanning, setIsScanning] = useState(!!scanning && !embeddedScanner);
  const { dosar, colete, setQueue } = useLocalCache();
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const modal = useModal();

  subscribe("CameraScanningChanged", ({ detail }) => setIsScanning(detail));

  const handleSearch = (barcode: string) => {
    let message = "";
    if (barcode) {
      const colet = colete.find((c) => c.IdSursa === +barcode.replace('B2B-', ''));
      if (colet && colet.ScanatCamion) {
        message = `Coletul "${colet.Descriere}" a fost scanat deja!`;
      } else if (colet) {
        setQueue("dbo.Colet", { ...colet, ScanatCamion: new Date() });
      } else {
        message = `Coletul ${barcode} nu face parte din dosarul ${dosar.Nume}!`;
      }
    }
    setError(message);
    message && toast.error(message);
  }

  if (dosar.NumarColete === dosar.ScanatCamion) {
    isScanning && setIsScanning(false);
    const body = <LivrareFacturaStatus
      {...{
        title: "Colete încărcate ",
        totalCount: dosar.NumarColete,
        doneCount: dosar.ScanatCamion,
      }}
    />;
    modal(body, "Scanare finalizată", () => navigate("/camion-livrat"));
  }

  return (
    <>
      {isScanning ? (
        <ScannerWebcam handleSearch={handleSearch} isScanning={isScanning} />
      ) : (
        <div className="row">
          <h5>Încărcare colete pe camion</h5>
          <ScannerInput {...{ handleSearch }} />
          <ErrorBox children={error} />
        </div>
      )}
      <LivrareFacturaList />
    </>
  );
};

export default CamionColetPage;
