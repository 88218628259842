import { FacturaEntity } from "../../../../backend/src/shared/entity";
import { RefuzIcon } from "./StatusIcons";

const ValoareDecontFactura = ({
    AchitatOnline,
    Valoare,
    NumarColete,
    ScanatRefuz,
    MotivRetur,
  }: FacturaEntity) => {
    if (AchitatOnline)
      return (
        <small className="text-muted justify-content-between">
          <i className="fa-light fa-credit-card"></i>
          <span>
            Achitat
            <br />
            online
          </span>
        </small>
      );
    if (ScanatRefuz === NumarColete)
      return (
        <div className="justify-content-between">
          <RefuzIcon />
          <div>
            <del>{Valoare.toFixed(2)} lei</del>
            <br />
            <small className="text-warning">{MotivRetur}</small>
          </div>
        </div>
      );
    if (ScanatRefuz > 0)
      return (
        <div className="justify-content-between">
          <i className="text-warning fa-xl fa-regular fa-circle-exclamation"></i>
          <div>
            {Valoare.toFixed(2)} lei
            <br />
            <small className="text-warning">Refuz parțial</small>
          </div>
        </div>
      );
    return (
      <span className="justify-content-between">
        <i className="fa-light fa-sack-dollar"></i>
        <span>{Valoare.toFixed(2)} lei</span>
      </span>
    );
  };
  
  export default ValoareDecontFactura;