import React from 'react'
import Ramburs from '../Ramburs'
import { FacturaEntity } from '../../../../backend/src/shared/entity'
import { LivratIcon, RefuzIcon } from './StatusIcons'
import { Progress } from './StatusLivrare'
import useLocalCache from '../../common/localCache'

const LivrareFacturaProgress = ({ factura = {} as FacturaEntity }) => {
    const { dosar } = useLocalCache();
    if (dosar.ScanatLivrat + dosar.ScanatRefuz === dosar.NumarColete) return <h2>Livrare completă <LivratIcon /> </h2>
    if (!factura?.Id) return <h5>Scanează un colet pentru {dosar.NumarColete === dosar.ScanatCamion ? 'livrare' : 'încarcare'}</h5>
    return (
        <>
            <h5 className="text-end">
                <div className="justify-content-between">
                    <Ramburs {...factura} />
                    <span>
                        <div className="justify-content-between">
                            {factura.ScanatRefuz ? (
                                <span>
                                    <RefuzIcon />
                                    <b className="text-larger ms-1">
                                        <Progress done={factura.ScanatRefuz} total={factura.NumarColete} />
                                    </b>
                                </span>
                            ) : (
                                <b></b>
                            )}
                            <span>
                                <LivratIcon /> Livrat
                                <b className="text-larger ms-1">
                                    <Progress done={factura.ScanatLivrat} total={factura.NumarColete} />
                                </b>
                            </span>
                        </div>
                        <div>
                            <small className="text-muted">
                                {factura?.Cumparator} - factura &nbsp;
                            </small>
                            {factura?.Document}
                        </div>
                    </span>
                </div>
            </h5>
        </>
    )
}

export default LivrareFacturaProgress