import LivrareFacturaItem from "./LivrareFacturaItem";
import ProcesVerbalSnapshot from "../ProcesVerbalSnapshot";
import useProcesVerbal from "../../hooks/useProcesVerbal";
import useLocalCache from "../../common/localCache";
import { LivrareFacturaStatus } from "./LivrareFacturaStatus";

const LivrareFacturaList = () => {
  const { procesVerbal, setProcesVerbal } = useProcesVerbal();
  const { facturi, colete } = useLocalCache();
  // derived state
  const displayBy: "ScanatCamion" | "ScanatLivrat" = facturi.find(
    (f) => f.NumarColete > f.ScanatCamion // f.ScanatLivrat || f.ScanatRefuz
  )
    ? "ScanatCamion"
    : "ScanatLivrat";

  const count =
    displayBy === "ScanatCamion"
      ? {
        total: facturi.reduce((acc, factura) => acc + factura.NumarColete, 0),
        done: facturi.reduce((acc, factura) => acc + factura[displayBy], 0),
        refuz: {
          colete: colete.filter((c) => c.ScanatRefuz).length,
          persoane: 0,
        },
      }
      : {
        total: facturi.length,
        done: facturi.filter(
          (f) => f.ScanatLivrat + f.ScanatRefuz === f.NumarColete
        ).length,
        refuz: {
          colete: colete.filter((c) => c.ScanatRefuz).length,
          persoane: facturi.filter((f) => f.ScanatRefuz).length,
        },
      };
  if (procesVerbal) return <ProcesVerbalSnapshot {...{ procesVerbal, setProcesVerbal }} />;
  return (
    <>
      <div className="row">
        <LivrareFacturaStatus
          title={
            displayBy === "ScanatCamion"
              ? "Colete încărcate "
              : "Facturi livrate "
          }
          totalCount={count.total}
          doneCount={count.done}
          refuzCount={count.refuz.colete}
        />
      </div>
      {facturi.map((factura) => (
        <LivrareFacturaItem
          {...{
            key: factura.Id,
            factura,
            setProcesVerbal,
            colete: colete.filter((c) => c.IdFactura === factura.Id),
          }}
        />
      ))}
    </>
  );
};

export default LivrareFacturaList;
