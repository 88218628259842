import { useState } from "react";
import { subscribe } from "../common/events";

const ModalSubscriber = () => {
  const [message, setMessage] = useState<null | {
    title: string;
    content: JSX.Element | string;
    action?: JSX.Element;
    onClose?: () => void;
  }>(null);
  const handleClose = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    setMessage(null);
    if (message?.onClose) message.onClose();
  }
  subscribe("DialogMessageChanged", (e) => setMessage(e.detail));
  if (!message) return <></>;
  return (
    <dialog open>
      <article>
        <header>
          <a
            href="/"
            aria-label="Close"
            className="close"
            onClick={handleClose}
          >
            {""}
          </a>
          <span className="text-large text-muted">{message.title}</span>
        </header>
        <p>{message.content}</p>
        <p className="justify-content-end">
          <a
            href="/"
            role="button"
            className={`secondary ${message.action ? "me-2" : ""}`}
            onClick={handleClose}
          >
            <i className="fa-solid fa-xmark-large text-warning"></i>
            Închide
          </a>
          {message.action}
        </p>
      </article>
    </dialog>
  );
};

export default ModalSubscriber;
