import { Link } from "react-router-dom";
import useModal from "../../hooks/useModal";
import { TransformComponent, TransformWrapper } from "react-zoom-pan-pinch";

const ProcesVerbalViewer = ({ procesVerbal = "", document = "" }) => {
    const modal = useModal();
    if (!procesVerbal) return (<></>);
    return (
      <Link
        to="/"
        onClick={(e) => [
          e.preventDefault(),
          modal(
            <TransformWrapper>
              <TransformComponent>
                <figure>
                  <img
                    src={procesVerbal}
                    alt="Proces verbal"
                  />
                </figure>
              </TransformComponent>
            </TransformWrapper>,
            `Proces verbal - ${document}`
          ),
        ]}
      >
        <i className="fa-xl fa-light fa-paperclip"></i>
      </Link>
    );
  }

export default ProcesVerbalViewer;