import { useEffect, useState } from "react";
import ErrorBox from "../ErrorBox";
import {
  ColetEntity,
  DosarEntity,
  FacturaEntity,
} from "../../../../backend/src/shared/entity";
import sql from "../../common/sql";
import { Progress } from "../livrare/StatusLivrare";
import { Link } from "react-router-dom";
import { PaletIcon } from "../livrare/StatusIcons";
import { toast } from "sonner";
import { publish } from "../../common/events";
import { StatusLivrareIcon } from "../livrare/StatusLivrareIcon";
import Fa from "../FontAwesome";

const DepozitPaletConsumer = ({ barcode = "" }) => {
  const [error, setError] = useState("");
  const [oldcode, setOldCode] = useState("");
  const [dosar, setDosar] = useState<DosarEntity>();
  const [factura, setFactura] = useState<FacturaEntity>();
  const [colet, setColet] = useState({} as ColetEntity);
  const [colete, setColete] = useState([] as ColetEntity[]);
  const [indexPalet, setIndexPalet] = useState(0);
  barcode = barcode.replace('B2B-', '');

  if (colet.Id && colete?.length && !colete?.includes(colet)) {
    // coletul a fost modificat, scris și recitit din baza de date
    // trebuie să-l actualizez și în listă
    setColete((colete) => colete?.map((c) => (c.Id === colet.Id ? colet : c)));
  }

  // handle events
  const handlePaletColet = (indexPalet: number) => {
    sql
      .saveEntity("dbo.Colet", {
        ...colet,
        ScanatPalet: new Date(),
        IndexPalet: indexPalet,
      })
      .then(setColet)
      .then(() => setIndexPalet(indexPalet))
      .then(() => publish("ColetChanged", colet))
      .then(() =>
        toast.success(`Coletul a fost marcat în "Palet ${indexPalet}"!`)
      )
      .catch(setError);
  };
  const handleColetLiber = (colet: ColetEntity) => {
    sql
      .saveEntity("dbo.Colet", {
        ...colet,
        ScanatLiber: new Date(),
      })
      .then(setColet)
      .then(() => setIndexPalet(-1))
      .then(() => publish("ColetChanged", colet))
      .then(() =>
        toast.success('Coletul a fost marcat "liber"!', {
          description: colet.Descriere,
        })
      )
      .catch(setError);
  };
  const handleResetColet = (colet: ColetEntity) => {
    setIndexPalet(0);
    sql
      .saveEntity("dbo.Colet", {
        ...colet,
        IndexPalet: 0,
        ScanatPalet: null,
        ScanatLiber: null,
      })
      .then(setColet)
      .then(() => publish("ColetChanged", colet))
      .catch(setError);
  };
  if (!barcode && error) setError("");
  if (barcode && barcode !== oldcode) {
    setOldCode(barcode);
    setError("");
    sql // caut dosarul aferent coletului scanat, el trebuie sa fie marcat ca paletat
      .coleteByBarcodeQuery({ barcode })
      .then(
        ([[dosar], [factura], colete]: [
          DosarEntity[],
          FacturaEntity[],
          ColetEntity[]
        ]) => {
          if (!dosar?.Id) {
            setError("Nu am găsit dosar pentru codul de bare scanat!");
            toast.error("Nu am găsit dosar pentru codul de bare scanat!");
          } else if (!dosar.Paletare) {
            setError(
              `Dosarul "${dosar.Nume}" nu este marcat că necesită "Paletare"!`
            );
            toast.error(
              `Dosarul "${dosar.Nume}" nu este marcat că necesită "Paletare"!`
            );
          } else if (dosar.Paletare) {
            setDosar(dosar);
            setFactura(factura);
            setColete(colete);
            setColet(colete.find((c) => c.IdSursa === +barcode) as ColetEntity);
          } else {
            setError("Ceva nu a mers bine!");
            toast.error("Ceva nu a mers bine!");
          }
        }
      )
      .catch(setError);
  }
  if (!barcode && dosar && colete.length) {
    setDosar(undefined);
    setColete([]);
  }

  if (error) return <ErrorBox>{error}</ErrorBox>;
  if (!dosar?.Id) return <h5>Scanează un colet</h5>;
  return (
    <OptiuniPaletareColet
      {...{
        dosar,
        factura,
        colet,
        colete,
        indexPalet,
        handlePaletColet,
        handleColetLiber,
        handleResetColet,
      }}
    />
  );
};

export default DepozitPaletConsumer;

const OptiuniPaletareColet = ({
  dosar,
  factura,
  colet,
  colete,
  indexPalet,
  handlePaletColet,
  handleColetLiber,
  handleResetColet,
}: {
  dosar: DosarEntity | undefined;
  factura: FacturaEntity | undefined;
  colet: ColetEntity;
  colete: ColetEntity[];
  indexPalet: number;
  handlePaletColet: (indexPalet: number) => void;
  handleColetLiber: (colet: ColetEntity) => void;
  handleResetColet: (colet: ColetEntity) => void;
}) => {
  const [details, setDetails] = useState(false);
  // derived state
  const coletCount = colete?.length || 0;
  const paletCount = colete?.filter((c) => c.ScanatPalet).length || 0;
  const liberCount = colete?.filter((c) => c.ScanatLiber).length || 0;
  const facturaCount =
    colete
      ?.filter((c) => c.IdFactura === factura?.Id)
      .filter((c) => c.ScanatLiber || c.ScanatPalet).length || 0;

  const numarPaleti =
    dosar?.NumarPaleti ||
    colete.reduce((max, c) => (c.IndexPalet > max ? c.IndexPalet : max), 1);

  const paleti = Array.from({ length: numarPaleti }, (_, i) => i + 1).reverse();
  const resetabil = colet.IndexPalet || colet.ScanatLiber;

  if (!resetabil && indexPalet) {
    // marchez coletul curent ca scanat
    // în paletul indexPalet sau Colet Liber pentru indexPalet = -1
    indexPalet === -1 ? handleColetLiber(colet) : handlePaletColet(indexPalet);
  }
  useEffect(() => {
    // document.getElementById("scannerInput")?.focus();
    if (paletCount + liberCount === coletCount)
      publish("PaletScanComplet", dosar);
  });
  return (
    <div className="mb-3">
      <p className="text-muted mb-1">{colet.Descriere}</p>
      <div className="justify-content-between">
        <h4 className="mb-1">
          <Link className="ms-2" to={`/dosar-details/${dosar?.Id}`}>
            <Fa light="folder-tree" />
            <Progress total={coletCount} done={paletCount + liberCount} />
          </Link>
        </h4>
        <h4 className="mb-1">
          <PaletIcon />
          &nbsp;
          <Progress total={factura?.NumarColete} done={facturaCount} />
          <Link
            className="ms-2"
            to=""
            onClick={(e) => [e.preventDefault(), setDetails((prev) => !prev)]}
          >
            {factura?.Document}
          </Link>
        </h4>
      </div>
      {details &&
        colete
          ?.filter((c) => c.IdFactura === factura?.Id)
          .map((colet) => (
            <p key={colet.Id} className="mb-0 mt-2 text-start">
              <StatusLivrareIcon {...colet} />
              &emsp;{colet.Descriere}
            </p>
          ))}
      <hr />
      {resetabil ? (
        <>
          <p>
            {colet.ScanatLiber ? (
              <>
                Marcat <b>"Colet liber"</b>
              </>
            ) : (
              <>
                Colet marcat în{" "}
                <b className="text-large">Palet {colet.IndexPalet}</b>
              </>
            )}
          </p>
          <Link
            to=""
            onClick={(e) => [e.preventDefault(), handleResetColet(colet)]}
          >
            <Fa light="rotate-left text-danger fa-xl" />
            Resetează marcaj colet
          </Link>
        </>
      ) : (
        <p className="justify-content-between text-larger mt-3 rtl">
          {!indexPalet &&
            paleti.map((index) => (
              <Link
                key={index}
                to=""
                className="me-2 mb-3"
                onClick={(e) => [e.preventDefault(), handlePaletColet(index)]}
              >
                Palet {index}
              </Link>
            ))}
          {dosar?.NumarPaleti === 0 && (
            <Link
              to=""
              className="me-2 mb-3"
              onClick={(e) => [
                e.preventDefault(),
                handlePaletColet(paleti.length + 1),
              ]}
            >
              <b>
                Palet nou <i className="fa-lg fa-solid fa-circle-plus"></i>
              </b>
            </Link>
          )}
          <Link
            to=""
            className="me-2 mb-3"
            onClick={(e) => [e.preventDefault(), handleColetLiber(colet)]}
          >
            Liber <Fa solid="box fa-lg" />
          </Link>
        </p>
      )}
    </div>
  );
};
