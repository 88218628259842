import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ScannerWebcam from "../../components/scanner/ScannerWebcam";
import { publish, subscribe } from "../../common/events";
import ScannerInput from "../../components/scanner/ScannerInput";
import useModal from "../../hooks/useModal";
import CamionPaletProgress from "../../components/CamionPaletProgress";
import useLocalCache from "../../common/localCache";
import { toast } from "sonner";
import sql from "../../common/sql";
import { PaletScanatEntity } from "../../../../backend/src/shared/entity";
import ErrorBox from "../../components/ErrorBox";

const CamionPaletPage = () => {
  const { paletQR, scanning = false } = useParams();
  const [barcode, setBarcode] = useState(paletQR);
  const { dosar, colete, setQueue, setEntity } = useLocalCache();
  const [error, setError] = useState("");
  const modal = useModal();
  const [isScanning, setIsScanning] = useState(!!scanning);
  const navigate = useNavigate();

  subscribe("CameraScanningChanged", (e) => e.detail !== isScanning && setIsScanning(e.detail));

  const handleSearch = (value: string) => {
    if (!value) return;
    const barcode = value.includes("/@") ? value.split("/@")[1] : value.replace("B2B-", "");
    let message = "";
    setError("");
    if (barcode) {
      const colet = colete.find((c) => c.IdSursa === +barcode && c.ScanatLiber);
      if (!dosar.Paletare) {
        message = `Dosarul "${dosar.Nume}" nu este marcat că necesită "Paletare"!`;
      } else if (colet && colet.ScanatCamion) {
        message = `Coletul "${colet.Descriere}" a fost deja scanat pe camion!`;
      } else if (colet) {
        setQueue("dbo.Colet", { ...colet, ScanatCamion: new Date(), ScanatLivrat: dosar.Offline ? new Date() : null });
      } else {
        // este scanare palet, verific dacă este un palet valid
        try {
          const palet = JSON.parse(
            atob(barcode.includes("/@") ? barcode.split("/@")[1] : barcode)
          );
          const colet = colete.find(
            (colet) =>
              colet.IndexPalet === palet.IndexPalet &&
              colet.IdDosar === palet.IdDosar
          );
          if (colet?.ScanatCamion) {
            toast.warning(`Paletul ${colet.IndexPalet} a fost deja scanat pe camion!`);
          } else if (colet) {
            // se trimite o entitate dbo.PaletScanat la server care va genera update pe toate coletele de pe palet pe coloana ScanatCamion
            // scanarea paletului ar trebui să se facă online, iar coletele din palet să fie marcate ca scanate pe camion
            sql.saveEntity("dbo.PaletScanat", { IndexPalet: colet.IndexPalet, IdDosar: colet.IdDosar, CreatLa: new Date() } as PaletScanatEntity)
              .then(() => {
                toast.success(`Palet ${colet.IndexPalet} scanat!`);
                colete.filter(c => c.IndexPalet === palet.IndexPalet)
                  .forEach((c) => setEntity("dbo.Colet", { ...c, ScanatCamion: new Date(), ScanatLivrat: dosar.Offline ? new Date() : null }));
              })
              .catch((err) => {
                // dacă nu are internet trebuie notificat să se conecteze la internet
                if (!window.navigator.onLine) {
                  setError(`Nu aveți conexiune la internet! Conectați-vă la internet și încercați din nou!`);
                  toast.error(`Nu aveți conexiune la internet! Conectați-vă la internet și încercați din nou!`);
                } else {
                  console.error(err);
                  message = `Eroare la scanarea paletului ${colet.IndexPalet}!`;
                }
              });
            if (message) {
              setError(message);
              toast.error(message);
            }
          } else {
            throw new Error(
              `Codul scanat nu este "colet" sau "palet" din ${dosar.Nume} !`
            );
          }
        } catch (e) {
          console.error(e);
          setError(`Codul scanat nu aparține de dosarul "${dosar.Nume}" !`);
          toast.error(`Codul scanat nu aparține de dosarul "${dosar.Nume}" !`);
        }
      }
    }
  };

  if (dosar.Offline && dosar.ScanatLivrat === dosar.NumarColete) {
    isScanning && setIsScanning(false);
    publish('CameraScanningChanged', false);
    modal(`Toată marfa din dosarul "${dosar.Nume}" a fost livrată`, "Scanare finalizată", () => navigate("/home"));
  } else if (dosar.ScanatCamion === dosar.NumarColete) {
    isScanning && setIsScanning(false);
    publish('CameraScanningChanged', false);
    modal(`Toată marfa din dosarul "${dosar.Nume}" a fost transferată pe camion`, "Scanare finalizată", () => navigate("/camion-livrat"));
  }

  if (barcode) { handleSearch(`/${barcode}`); setBarcode(""); };

  return (
    <>
      {isScanning ? (
        <ScannerWebcam {...{ handleSearch, isScanning }}>
          <CamionPaletProgress />
        </ScannerWebcam>
      ) : (
        <div>
          <h5>Transfer paleți/colete libere pe camion</h5>
          <ScannerInput {...{ handleSearch }} />
          <ErrorBox children={error} />
          <CamionPaletProgress />
        </div>
      )}
    </>
  );
};

export default CamionPaletPage;
