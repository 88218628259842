import { useEffect, useState } from "react";

const storageKey = "__embedded_scanner__";
export function useScanner() {
  const [embeddedScanner, setEmbeddedScanner] = useState(
    localStorage.getItem(storageKey) === "true"
  );
  useEffect(() => {
    localStorage.setItem(storageKey, embeddedScanner.toString());
  }, [embeddedScanner]);
  return { embeddedScanner, setEmbeddedScanner };
}
