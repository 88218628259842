import env from "./env";

export const passwordReset = async (email: string, password: string) => {
  const response = await fetch(`${env.API_URL}/passwordReset`, {
    method: "POST",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ email, password }),
  });
  let responseJson = await response.json();
  if (response.status === 400) {
    throw responseJson;
  }
  return responseJson;
};

export const passwordRecover = async (email: string) => {
  const response = await fetch(`${env.API_URL}/passwordRecover`, {
    method: "POST",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ email }),
  });
  let responseJson = await response.json();
  if (response.status === 400) {
    throw responseJson;
  }
  return responseJson;
};

export const loginUser = async (body: LoginInfo) => {
  const response = await fetch(`${env.API_URL}/loginUser`, {
    method: "POST",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(body),
  });
  let responseJson = await response.json();
  if (response.status === 400) {
    throw responseJson;
  }
  return responseJson;
};

export const registerUser = async (body: RegisterInfo) => {
  const response = await fetch(`${env.API_URL}/registerUser`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(body),
  });
  let responseJson = await response.json();
  if (response.status === 400) {
    throw responseJson;
  }
  return responseJson;
};

export const logoutUser = async () => {
  const response = await fetch(`${env.API_URL}/logoutUser`);
  return await response.json();
};

export const getCookie = (name: keyof UserInfo) =>
  document.cookie
    .split("; ")
    .find((row) => row.startsWith(`${name}=`))
    ?.split("=")[1] || "";

export const cookieUserInfo = (): UserInfo => ({
  email: getCookie("email"),
  name: getCookie("name"),
  role: getCookie("role") as UserRole,
  token: getCookie("token"),
  isSofer: getCookie("role") === "sofer",
  isAdmin: getCookie("role") === "admin",
});

export type UserInfo = {
  role: UserRole;
  email: string;
  name: string;
  token: string;
  isSofer: boolean;
  isAdmin: boolean;
};
export type UserRole = "sofer" | "admin" | "operator";

export type LoginInfo = {
  email: string;
  password?: string;
  role?: UserRole;
  auto?: string;
};
export type RegisterInfo = {
  name: string;
  email: string;
  password: string;
  passwordRepeat: string;
};
