import { useState } from "react";
import ScannerWebcam from "../../components/scanner/ScannerWebcam";
import DepozitPaletConsumer from "../../components/consumer/DepozitPaletConsumer";
import { publish, subscribe } from "../../common/events";
import { PaletIcon } from "../../components/livrare/StatusIcons";
import useModal from "../../hooks/useModal";
import ScannerInput from "../../components/scanner/ScannerInput";

const DepozitPaletPage = () => {
  const [barcode, setBarcode] = useState("");
  const [isScanning, setIsScanning] = useState(false);
  const modal = useModal();

  const handleSearch = (value: string) => setBarcode(value);

  subscribe("CameraScanningChanged", ({ detail }) => setIsScanning(detail));
  subscribe("PaletScanComplet", () => [
    setIsScanning(false),
    publish("CameraScanningChanged", false),
    modal(
      <ins>Toate coletele din dosar au fost marcate "palet" sau "liber".</ins>,
      "Paletare dosar completă"
    ),
  ]);

  return (
    <>
      {isScanning ? (
        <ScannerWebcam {...{ handleSearch, isScanning, setIsScanning }}>
          <DepozitPaletConsumer barcode={barcode} />
        </ScannerWebcam>
      ) : (
        <div>
          <h5>
            <PaletIcon /> &nbsp; Paletare colete
          </h5>
          <ScannerInput {...{ handleSearch }} />
          <DepozitPaletConsumer {...{ barcode }} />
        </div>
      )}
    </>
  );
};

export default DepozitPaletPage;
