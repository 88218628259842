import { ColetEntity, DosarEntity, FacturaEntity } from "../../../../backend/src/shared/entity";
import { Link } from "react-router-dom";
import { StatusLivrare } from "./StatusLivrare";
import Phone from "../Phone";
import { StatusLivrareIcon } from "./StatusLivrareIcon";
import { useState } from "react";
import Ramburs from "../Ramburs";
import useLocalCache from "../../common/localCache";
import ProcesVerbalStatus from "./ProcesVerbalStatus";
import OperareManualaStatusColet from "./OperareManualaStatusColet";

const LivrareFacturaItem = ({
  factura = {} as FacturaEntity,
  colete = [] as ColetEntity[],
  setProcesVerbal = (factura: FacturaEntity | DosarEntity) => { }
}) => {
  const { Cumparator, Telefon, Document, NumarColete } = factura;
  const [details, setDetails] = useState(false);
  const [colet, setColet] = useState<ColetEntity | null>(null);
  const { dosar } = useLocalCache();
  const etapa: "încărcare" | "livrare" = dosar.ScanatCamion === dosar.NumarColete ? "livrare" : "încărcare";

  const handleChangeStatus = (colet: ColetEntity) => {
    if (etapa === "încărcare" && colet.ScanatCamion) return;
    // if (etapa === "livrare" && (colet.ScanatLivrat || colet.ScanatRefuz)) return; // îl poate trece între cele două stări
    setColet({ ...colet });
  }

  return (
    <div className="row">
      <article>
        <div className="justify-content-between align-items-start">
          <div className="-col-5 me-0 pe-0">
            <span className="text-center">
              <p className="text-muted m-0">
                <b className="text-larger">{NumarColete}</b>
              </p>
              <p className="text-muted mb-2">
                <small>Colete</small>
              </p>
            </span>
            <Ramburs {...factura} />
          </div>
          <div className="-col-7 ms-0 ps-0 text-end">
            <span className="text-muted text-large">{Cumparator}</span> <br />
            <label className="mb-2 text-large">
              <Phone>{Telefon}</Phone>
            </label>
            <StatusLivrare {...factura} />
            {/* <span className="text-muted">Valoare </span>
            <strong> {Valoare.toFixed(2)} lei</strong> */}
          </div>
        </div>
        <hr />
        <div className="justify-content-between">
          <ProcesVerbalStatus procesVerbal={factura} setProcesVerbal={setProcesVerbal} />
          <Link
            to=""
            onClick={(e) => [e.preventDefault(), setDetails(!details)]}
          >
            Factura {Document}
            <i className="ms-2 fa-fw fa-solid fa-arrow-down-short-wide"></i>
          </Link>
        </div>
        <div>
          {details &&
            colete?.map((colet) => (
              <p key={colet.Id} className="text-start" onClick={e => [e.preventDefault(), handleChangeStatus(colet)]}>
                <StatusLivrareIcon {...colet} /> {colet.Descriere}
              </p>
            ))}
        </div>
      </article>
      <OperareManualaStatusColet colet={colet} setColet={setColet} etapa={etapa} />
    </div>
  );
};

export default LivrareFacturaItem;
