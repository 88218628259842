const LoginContainer = ({ children }: any) => {
  return (
    <div className="d-flex justify-content-center">
      <article className="mt-0 pt-4" style={{ maxWidth: 500 }}>
        {children}
      </article>
    </div>
  );
};

export default LoginContainer;
