import React from 'react';
import { publish, subscribe } from '../../common/events';
// import useLocalCache from '../../common/localCache';
// import { Link } from 'react-router-dom';

// const TestPage = () => {
//     const { dosar } = useLocalCache();
//     return (
//         <div>
//             <h3>Colete încărcate {dosar.ScanatCamion} / {dosar.NumarColete}</h3>
//             <hr />
//             <TestColet />
//         </div>
//     )
// }

// const TestColet = () => {
//     const { colete, setEntity } = useLocalCache();
//     return (
//         <div>
//             <h3>Colete</h3>
//             <ul>
//                 {colete.map((c) => (
//                     <li key={c.Id}>{c.ScanatCamion ? '✅' : '❌'}
//                         <Link to='' onClick={e => [e.preventDefault(), setEntity('dbo.Colet', { ...c, ScanatCamion: c.ScanatCamion ? null : new Date() })]}>{c.Descriere}</Link>
//                     </li>
//                 ))}
//             </ul>
//         </div>
//     )
// }

const TestPage = () => {
    const { state, setState } = useHook();
    console.log('TestPage »', state);
    return (
        <div>
            <button onClick={e => [e.preventDefault(), setState()]}>{state.value}</button>
            <TestHook />
            <TestHook />
        </div>
    )
}

const TestHook = () => {
    const { state, setState } = useHook();
    const [uuid] = React.useState(Math.random().toString(36).substring(7));
    console.log('TestHook »', uuid, '»', state);
    const handleChanges = () => {
        setState();
    }
    return (
        <div>
            <button onClick={e => [e.preventDefault(), handleChanges()]}>{state.value}</button>
        </div>
    )
}

export default TestPage

const state = { value: 0 };
function useHook() {
    const [local, setLocal] = React.useState({ value: 0 });
    console.log('»» useHook »', state);
    subscribe('localCacheChanged', () => setLocal({ value: state.value }));
    return { state: local, setState: () => [state.value += 1, publish('localCacheChanged')] };
}
