import { Link } from "react-router-dom";
import { useUser } from "../../hooks/useUser";
import { PaletIcon, ReturIcon } from "../livrare/StatusIcons";

const NavbarItems = () => {
  const { user } = useUser();
  if (user.role === "admin")
    return (
      <>
        <ul className="d-none d-md-flex">
          <li>
            <Link to="/raport/sumar-articole-dosar">
              <i className="fa-lg fa-fw fa-regular fa-print"></i>
            </Link>
          </li>
          <li>
            <Link to="/barcode-facturi">
              <i className="fa-lg fa-fw fa-regular fa-barcode"></i>
            </Link>
          </li>
          <li>
            <Link to="/dosar-index">
              <i className="fa-lg fa-fw fa-regular fa-folder-tree"></i>
            </Link>
          </li>
          <li>
            <Link to="/config">
              <i className="fa-lg fa-fw fa-regular fa-screwdriver-wrench"></i>
            </Link>
          </li>
        </ul>
        <ul className="text-x-large">
          <li>
            <Link to="/depozit-retur" >
              <ReturIcon />
            </Link>
          </li>
          <li>
            <Link to="/depozit-palet">
              <PaletIcon />
            </Link>
          </li>
        </ul>
      </>
    );
  return <></>;
};

export default NavbarItems;
