import { useUser } from "../hooks/useUser";
import DosarIndexPage from "./dosar/DosarIndexPage";
import LoginPage from "./user/LoginPage";
import { useActiveRoute } from "../hooks/useActiveRoute";
import DosarOfflineLivrat from "../components/DosarOfflineLivrat";
import useLocalCache from "../common/localCache";

const HomePage = () => {
  const { user } = useUser();
  const { dosar } = useLocalCache();
  const { setActiveRoute } = useActiveRoute();

  if (user.role === "sofer" && dosar.Offline && dosar.ScanatLivrat === dosar.NumarColete) {
    return <DosarOfflineLivrat />;
  }
  if (user.role === "sofer") { // soferul va fi redirectionat catre pagina de încarcare/mutare paleți/livrare
    return setActiveRoute() && <progress />;
  }
  if (user.email) return <DosarIndexPage />;
  return <LoginPage />;
};

export default HomePage;
