import { SyntheticEvent, useEffect, useState } from "react";
import { DosarEntity, FacturaEntity } from "../../../backend/src/shared/entity";
import useLocalCache from "../common/localCache";
import CameraSnapshot from "./CameraSnapshot";
import { publish } from "../common/events";
import { Link } from "react-router-dom";

const ProcesVerbalSnapshot = ({ procesVerbal = {} as FacturaEntity | DosarEntity, setProcesVerbal = (procesVerbal: FacturaEntity | DosarEntity | undefined) => { } }) => {
  const [picture, setPicture] = useState(procesVerbal.ProcesVerbal);
  const { setQueue } = useLocalCache();
  const title = (procesVerbal as DosarEntity).Nume
    ? (procesVerbal as DosarEntity).Nume
    : `${(procesVerbal as FacturaEntity).Document} - ${(procesVerbal as FacturaEntity).Cumparator}`;
    
  //effects
  useEffect(() => {
    publish("ProcesVerbalSnapshot", true);
    return () => publish("ProcesVerbalSnapshot", false);
  }, []);

  // events
  const handleSave = (e: SyntheticEvent) => {
    e.preventDefault();
    setQueue((procesVerbal as DosarEntity).Nume ? "dbo.Dosar" : "dbo.Factura", { ...procesVerbal, ProcesVerbal: picture });
    setProcesVerbal(undefined);
  }

  return (
    <>
      <h5 className="text-center mb-0">
        {title}
      </h5>
      {picture ? (
        <>
          <article className="m-0 py-3 text-x-large text-center">
            <Link
              to=""
              className="d-inline w-auto float-start"
              onClick={(e) => [e.preventDefault(), setPicture("")]}
            >
              <i className="fa-2xl fa-solid fa-circle-camera"></i>
            </Link>
            <b className="text-bold-3"> Proces verbal </b>
            <Link
              to="/"
              className="d-inline w-auto float-end"
              onClick={handleSave}
            >
              <i className="fa-2xl fa-solid fa-circle-check text-success"></i>
            </Link>
          </article>
          <img src={picture} alt="Proces Verbal" className="w-100" />
        </>
      ) : (
        <CameraSnapshot active={true} handleResult={setPicture} />
      )}
    </>
  );
};

export default ProcesVerbalSnapshot;
