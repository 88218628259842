import { useState } from "react";
import { useParams } from "react-router-dom";
import ScannerWebcam from "../../components/scanner/ScannerWebcam";
import LivrareFacturaList from "../../components/livrare/LivrareFacturaList";
import ScannerInput from "../../components/scanner/ScannerInput";
import { publish, subscribe } from "../../common/events";
import useModal from "../../hooks/useModal";
import useProcesVerbal from "../../hooks/useProcesVerbal";
import { useScanner } from "../../hooks/useScanner";
import useLocalCache from "../../common/localCache";
import { toast } from "sonner";
import { ColetEntity } from "../../../../backend/src/shared/entity";
import ErrorBox from "../../components/ErrorBox";
import LivrareFacturaProgress from "../../components/livrare/LivrareFacturaProgress";
import { useActiveRoute } from "../../hooks/useActiveRoute";

const CamionLivratPage = () => {
  const { scanning = false } = useParams();
  const { embeddedScanner } = useScanner();
  const { procesVerbal } = useProcesVerbal();
  const [error, setError] = useState("");
  const [isScanning, setIsScanning] = useState(
    !procesVerbal && !!scanning && !embeddedScanner
  );
  const { dosar, facturi, colete, setQueue } = useLocalCache();
  const [PVSnapshot, setPVSnapshot] = useState(false);
  const modal = useModal();
  const { setActiveRoute } = useActiveRoute();

  subscribe("CameraScanningChanged", (e) => [
    setIsScanning(e.detail),
  ]);
  subscribe("ProcesVerbalSnapshot", (e) => [
    setPVSnapshot(e.detail),
  ]);

  subscribe("FacturaScanComplet", (e) => [
    publish("CameraScanningChanged", false),
    modal(e.detail, "Scanare completă"),
  ]);
  if (colete.find(c => !c.ScanatCamion)) setActiveRoute();
  const facturaCurenta = () => facturi.find((f) => f.ScanatLivrat + f.ScanatRefuz && f.ScanatLivrat + f.ScanatRefuz < f.NumarColete);

  const saveColet = (colet: ColetEntity) => {
    setError("");
    setQueue("dbo.Colet", { ...colet, ScanatLivrat: new Date(), ScanatRefuz: null, ManualLivrat: false });
    const factura = facturi.find((f) => f.Id === colet.IdFactura);
    if (factura && factura.NumarColete === factura.ScanatLivrat + factura.ScanatRefuz) {
      publish(
        "FacturaScanComplet",
        <span className="text-success">
          Toate coletele din factura {factura?.Document} au fost scanate!
        </span>
      );
    }
  };

  const handleSearch = (barcode: string) => {
    setError("");
    if (barcode) {
      const colet = colete.find((c) => c.IdSursa === +barcode.replace('B2B-', ''));
      const factura = facturaCurenta() || facturi.find((f) => f.Id === colet?.IdFactura);

      if (colet?.IdFactura !== factura?.Id) {
        let message = `Coletul ${barcode} nu face parte din dosarul ${dosar.Nume}!`;
        if (colet && factura) {
          message = `Coletul ${colet.Descriere} nu face parte din factura ${factura.Document}!`;
        }
        setError(message);
        modal(
          <span className="text-danger">{message}</span>,
          "Scanare eronată"
        );
      } else if (colet && colet.ScanatLivrat) {
        let message = `Coletul ${colet.Descriere} a fost scanat deja!`;
        toast(message);
      } else if (colet && colet.ScanatRefuz) {
        if (
          window.confirm(
            `Coletul ${colet.Descriere} este marcat "refuzat". Doriți să-l marcați "livrat"?`
          )
        ) {
          saveColet(colet);
        }
      } else if (colet && !colet.ScanatLivrat) {
        saveColet(colet);
      } else {
        let message = `Coletul ${barcode} nu face parte din dosarul ${dosar.Nume}!`;
        setError(message);
        toast.error(message);
      }
    }
  }

  return (
    <>
      {isScanning ? (
        <ScannerWebcam
          {...{
            handleSearch,
            isScanning,
          }}
        />
      ) : (
        PVSnapshot || (
          <>
            <LivrareFacturaProgress factura={facturaCurenta()} />
            <div className="row">
              <ScannerInput {...{ handleSearch }} />
              <ErrorBox children={error} />
            </div>
          </>
        )
      )}
      <LivrareFacturaList />
    </>
  );
};

export default CamionLivratPage;
