import { useContext, useState } from "react";
import { Link } from "react-router-dom";
import { TitleContext } from "../../App";
import NavbarItems from "./NavbarItems";
import { useUser } from "../../hooks/useUser";
import { publish, subscribe } from "../../common/events";
import { useActiveRoute } from "../../hooks/useActiveRoute";
import { useScanner } from "../../hooks/useScanner";
import Fa from "../FontAwesome";
import useLocalCache from "../../common/localCache";

const Navbar = () => {
  const { user } = useUser();
  const { dosar } = useLocalCache();
  const { title } = useContext(TitleContext);
  const [cameraSnapshot, setCameraSnapshot] = useState(false);
  const [cameraScanning, setCameraScanning] = useState(false);
  const [PVSnapshot, setPVSnapshot] = useState(false);
  const { setActiveRoute } = useActiveRoute();
  const { embeddedScanner } = useScanner();
  const setRoute = () => {
    const playBeep = () => document.querySelector("audio")?.play();
    if (!embeddedScanner) {
      playBeep();
      publish("CameraScanningChanged", true);
    }
    setActiveRoute(embeddedScanner ? "" : "scanning");
  };
  subscribe("ProcesVerbalSnapshot", (e) => setPVSnapshot(e.detail));
  subscribe("CameraSnapshotChanged", (e) => setCameraSnapshot(e.detail));
  subscribe("CameraScanningChanged", (e) => setCameraScanning(e.detail));

  // console.log({ cameraSnapshot, cameraScanning, PVSnapshot });

  if (cameraSnapshot || cameraScanning || PVSnapshot) return <></>;
  return (
    <header>
      <nav>
        <ul>
          <li>
            <Link to="/">
              <img src="/favicon.ico" alt="logo" />
            </Link>
          </li>
          <li className="d-none d-md-block">{title}</li>
        </ul>
        {user.role === "sofer" && (
          <ul className="d-md-none pb-2">
            <li>
              <a href="https://cloudscape.gitbook.io/laguna-delivery-app/" target="_blank" rel="noreferrer">
                <i className="fa-2xl fa-fw fa-light fa-circle-question"></i>
              </a>
            </li>
            <li>
              <Link to="/camion-decont">
                <i className="fa-2xl fa-fw fa-light fa-sack-dollar"></i>
              </Link>
            </li>
            {!(dosar && dosar.Offline) &&
              <li>
                <Link to="/camion-refuz-option">
                  <Fa light="hand fa-2xl fa-fw text-danger" />
                </Link>
              </li>
            }
            <li>
              <Link to="" onClick={(e) => [e.preventDefault(), setRoute()]}>
                <Fa regular="barcode-read fa-2xl fa-fw" />
              </Link>
            </li>
          </ul>
        )}
        {user.role !== "sofer" && <NavbarItems />}
        <ul className="pb-2">
          <li className="text-end">
            <Link to={user.name ? "/config" : "/login"}>
              {!user.name && (
                <i className="fa-fw fa-regular fa-lg fa-user-unlock"></i>
              )}
              <span
                className="d-md-none"
                dangerouslySetInnerHTML={{
                  __html: user.name.replaceAll(" ", "</br>"),
                }}
              ></span>
              <span className="d-none d-md-flex">{user.name}</span>
            </Link>
          </li>
        </ul>
      </nav>
      <hr />
    </header>
  );
};

export default Navbar;
