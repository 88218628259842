import { toast } from "sonner";
import useLocalCache from "../common/localCache";
import { useNavigate } from "react-router-dom";

const message = "Nici un dosar încărcat!";
const description = "Scanează codul QR al dosarului!";
export function useActiveRoute() {
  const navigate = useNavigate();
  const { dosar, colete } = useLocalCache();
  let pageUrl = "";
  const setActiveRoute = (scanning = "") => {
    if (!dosar.Id) {
      return setTimeout(() => toast.error(message, { description }));
    }
    // verific statusul dosarului, bazat pe coletele scanate
    if (dosar.Offline && dosar.ScanatLivrat === dosar.NumarColete) {
      pageUrl = `/home`;
    } else if (dosar.ScanatCamion === dosar.NumarColete && !colete.find((c) => !c.ScanatCamion)) {
      // încarcarea pe camion este completă, se poate face livrarea
      pageUrl = `/camion-livrat/${scanning}`;
    } else if (dosar.Paletare) {
      // trebuie facută mutarea pe camion de pe tir
      pageUrl = `/camion-palet/%20/${scanning}`;
    } else {
      // trebuie finalizată încărcarea pe camion
      pageUrl = `/camion-colet/${scanning}`;
    }
    console.log("setActiveRoute:", pageUrl);
    return setTimeout(() => pageUrl && navigate(pageUrl));
  };

  return { setActiveRoute };
}
