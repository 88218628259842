const ErrorBox = ({ children }: any) => {
  if (!children) return <></>;

  let error = "";
  if (children?.message) error = children.message;
  else if (typeof children === "string") {
    try {
      error = JSON.parse(children).message;
    } catch (e) {}
  }

  return (
    <fieldset>
      {children && (
        <article className="border-danger m-0 p-3">
          {typeof children === "string" || error ? (
            <small
              style={{ overflowWrap: "break-word" }}
              dangerouslySetInnerHTML={{ __html: error || children }}
            ></small>
          ) : (
            <small>{children}</small>
          )}
        </article>
      )}
    </fieldset>
  );
};

export default ErrorBox;
