import { useEffect } from "react";
import { Link } from "react-router-dom";
import { Result, useZxing } from "react-zxing";
import { publish } from "../../common/events";

const ScannerWebcam = ({
  handleSearch = (result: string)=>{},
  isScanning = false,
  setIsScanning = (paused: boolean): void => void 0,
  children = <></>,
}) => {
  const { ref } = useZxing({
    paused: !isScanning,
    timeBetweenDecodingAttempts: 200,
    onDecodeResult: (result: Result) => [
      playBeep(),
      handleSearch(result.getText()),
    ],
  });

  useEffect(() => {
    publish("CameraScanningChanged", isScanning);
    isScanning && playBeep();
  }, [isScanning]);

  const playBeep = () => document.querySelector("audio")?.play();

  return (
    <>
      <article className="p-0">
        <header className="mb-0 pb-0">{children}</header>
        <div className="position-relative">
          <video ref={ref} style={{ width: "100%" }} />
          <Link
            to=""
            className="close-camera"
            onClick={(e) => [
              e.preventDefault(),
              setIsScanning(false),
              ref.current?.pause(),
              publish("CameraScanningChanged", false),
            ]}
          >
            <i className="text-danger fa-2xl fa-solid fa-circle-xmark"></i>
          </Link>
        </div>
      </article>
    </>
  );
};

export default ScannerWebcam;
